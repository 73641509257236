import Text from "common/values/text/text";

export default class ProjectName extends Text {
    public override clone(): ProjectName {
        return new ProjectName(this.value);
    }
    public static get Prototype(): ProjectName {
        return new ProjectName('Prototype');
    }
    public get isEmpty(): boolean {
        return this.value === '';
    }
    public fromObject(obj: any): ProjectName | null {
        return new ProjectName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
}
