import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import * as Constants from 'common/helpers/constants';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import Session from 'users/session/session';
import { useSession } from 'users/session/session-context';

interface RealtimeContextType {
  connection: HubConnection | null;
  startConnection: () => Promise<void>;
}

interface RealtimeProviderProps {
  children: ReactNode;
  hubName: HubName;
}

export enum HubName {
  Notification = 'notificationHub',
  Proposal = 'proposalHub',
  Message = 'messageHub',
  Networking = 'networkingHub'
}

const RealtimeNotificationContext = createContext<RealtimeContextType | undefined>(undefined);
const RealtimeProposalContext = createContext<RealtimeContextType | undefined>(undefined);
const RealtimeMessageContext = createContext<RealtimeContextType | undefined>(undefined);
const RealtimeNetworkingContext = createContext<RealtimeContextType | undefined>(undefined);

export const RealtimeProvider: React.FC<RealtimeProviderProps> = ({ children, hubName }) => {

  const connectionRef = useRef<HubConnection | null>(null);
  const session = useSession();

  useEffect(() => {
    if (connectionRef.current && connectionRef.current.state !== HubConnectionState.Disconnected)
      return;

    connectionRef.current = new HubConnectionBuilder()
      .withUrl(
        `${Constants.apiBaseUrl}/${hubName}`,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => Session.loadFromStorage(() => { }).authToken?.value ?? ''
        }
      )
      .withAutomaticReconnect()
      .build();

    connectionRef.current.onreconnecting((error) => {
      console.log(`Reconnecting to ${hubName}...`, error);
    });

    connectionRef.current.onreconnected((connectionId) => {
      console.log('Reconnected. Connection ID:', connectionId);
    });

    connectionRef.current.onclose(async () => {
      console.log(`${hubName} connection closed. Attempting to reconnect...`);
      await connectionRef?.current?.start();
    });

    startConnection();

    return () => {
      if (connectionRef.current?.state === HubConnectionState.Connected) {
        connectionRef.current?.stop();
      }
    };
  }, [connectionRef.current]);

  const startConnection = async () => {
    if (!session.isLoggedIn || !connectionRef.current) return;
    if (connectionRef.current.state === HubConnectionState.Disconnected) {
      try {
        await connectionRef.current?.start();
        console.log(`Connected to ${hubName}`);
      } catch (err) {
        console.error(`${hubName} connection connection failed: `, err);
      }
    }
  };

  const contextValue = useMemo(() => ({
    connection: connectionRef.current, startConnection
  }), [connectionRef.current, startConnection]);

  if (hubName === HubName.Notification) {
    return (
      <RealtimeNotificationContext.Provider value={contextValue}>
        {children}
      </RealtimeNotificationContext.Provider>
    );
  }
  if (hubName === HubName.Proposal) {
    return (
      <RealtimeProposalContext.Provider value={contextValue}>
        {children}
      </RealtimeProposalContext.Provider>
    );
  }
  if (hubName === HubName.Message) {
    return (
      <RealtimeMessageContext.Provider value={contextValue}>
        {children}
      </RealtimeMessageContext.Provider>
    );
  }
  if (hubName === HubName.Networking) {
    return (
      <RealtimeNetworkingContext.Provider value={contextValue}>
        {children}
      </RealtimeNetworkingContext.Provider>
    );
  }
};

export const useRealtime = (hubName: HubName): RealtimeContextType => {
  let context;
  if (hubName === HubName.Notification) {
    context = RealtimeNotificationContext;
  }
  if (hubName === HubName.Proposal) {
    context = RealtimeProposalContext;
  }
  if (hubName === HubName.Message) {
    context = RealtimeMessageContext;
  }
  if (hubName === HubName.Networking) {
    context = RealtimeNetworkingContext;
  }
  if (!context) {
    throw new Error(`Invalid hub name: ${hubName}`);
  }
  const contextType = useContext(context);
  if (!contextType) {
    throw new Error('useRealtime must be used within a RealtimeProvider');
  }
  return contextType;
};
