import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornmentProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AccountType } from "common/values/account-type/account-type";
import Date from "common/values/date/date";
import Forum from "messaging/entities/forum/forum";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import { useSession } from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import ClientSelector from "work/view/components/client-selector";

const ProposalContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
  display: "grid",
  gap: theme.spacing(6),
  gridTemplateColumns: "auto auto",
  width: "100%",
}));
const GeneralSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const DateSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .MuiFormControl-root": {
    marginTop: theme.spacing(1),
  },
}));
const ClientInput = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NameTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const DescriptionTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NegotiableCheckbox = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
const DateContainer = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(1, 0),
  width: "100%",
  "& > span:first-of-type": {
    flex: 1,
  },
}));
const DateControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
}));
const DateFieldButtons = styled("span")(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  "& > :first-child": {
    marginRight: 0,
  },
}));

type DetailsTabProps = {
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableCommenting: boolean;
  disableEditing?: boolean;
  commentForums: Forum[];
  isRequesting?: boolean;
  onChange: () => void;
  onSelectingClientChange: (isSelectingClient: boolean) => void;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
};

export default function DetailsTab(props: Readonly<DetailsTabProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableCommenting,
    disableEditing,
    commentForums,
    isRequesting,
    onChange,
    onSelectingClientChange,
    onCommentsClicked,
  } = props;

  const [nameValue, setNameValue] = useState<string>(
    proposalBuilder.currentSpec.name?.value ?? ""
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(
    proposalBuilder.currentSpec.description?.value ?? ""
  );
  const [isNegotiable, setIsNegotiable] = useState<boolean>(
    proposalBuilder.currentSpec.negotiable ?? true
  );
  const [isUsingResponseDueBy, setIsUsingResponseDueBy] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.responseDueBy)
  );
  const [responseDueBy, setResponseDueBy] = useState<Moment | null>(
    proposalBuilder.currentSpec.responseDueBy?.value ?? null
  );
  const [isUsingStartDate, setIsUsingStartDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.startDate)
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    proposalBuilder.currentSpec.startDate?.value ?? null
  );
  const [isUsingEndDate, setIsUsingEndDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.endDate)
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    proposalBuilder.currentSpec.endDate?.value ?? null
  );
  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false);
  const [clientSelectorAnchor, setClientSelectorAnchor] =
    useState<HTMLDivElement>();
  const [clientName, setClientName] = useState<string>("");

  const session = useSession();

  useEffect(() => {
    if (proposalBuilder.currentSpec.client) {
      setClientName(proposalBuilder.currentSpec.client.name.toString());
    } else {
      setDefaultProposalClient();
    }
  }, []);

  function getMinValidDate(compareDate: Moment, daysToAdd: number = 0) {
    const currentDateUnix = moment().unix();
    const compareDateUnix = compareDate.unix();

    return moment
      .unix(Math.max(currentDateUnix, compareDateUnix))
      .add(daysToAdd, "day");
  }

  function setDefaultProposalClient() {
    if (
      session.accountType === AccountType.Client &&
      session.context?.isClientRepresentative &&
      session.user?.asClientRepresentative &&
      !proposalBuilder.currentSpec.client
    ) {
      proposalBuilder.setClient(session.user.asClientRepresentative);
      setClientName(session.user?.name?.toString() ?? "");
    }
  }

  function handleSelectClientClicked(event: React.MouseEvent<HTMLDivElement>) {
    const inputElements = event.currentTarget.getElementsByTagName("input");
    setClientSelectorAnchor(
      inputElements.length > 0 ? inputElements[0] : event.currentTarget
    );
    setIsSelectingClient(true);
    onSelectingClientChange(true);
  }

  async function handleClientUpdated(userInfo?: NetworkedUserInfo) {
    if (!userInfo?.userId || !userInfo?.companyEntityId || !userInfo?.name) {
      proposalBuilder.setClient();
      setClientName("");
      onChange();
    } else {
      proposalBuilder.setClient(
        new EntityClientRepresentative(
          userInfo.userId,
          userInfo.companyEntityId,
          userInfo.name
        )
      );
      setClientName(userInfo.name?.toString() ?? "");
      onChange();
    }
  }

  function renderCommentsButton(field: ProposalField) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(field);
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={!getForumForField(field, commentForums)}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderTextFieldButtons(
    field: "name" | "description",
    isEditing: boolean,
    setter: (value: React.SetStateAction<boolean>) => void,
    callback: (newValue: string) => void
  ) {
    return (
      <>
        {!disableEditing && !isEditing && (
          <Tooltip title="Edit">
            <span>
              <IconButton
                tabIndex={-1}
                onClick={(event) => {
                  event.stopPropagation();
                  setter(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {isEditing && (
          <>
            <Tooltip title="Submit">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    callback(field === "name" ? nameValue : descriptionValue);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel">
              <span>
                <IconButton
                  datatype="cancel"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setter(false);
                    if (field === "name") {
                      setNameValue(
                        proposalBuilder.currentSpec.name?.value ?? ""
                      );
                    }
                    if (field === "description") {
                      setDescriptionValue(
                        proposalBuilder.currentSpec.description?.value ?? ""
                      );
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  function renderDateFieldButtons(
    params: InputAdornmentProps,
    field: ProposalField
  ) {
    return (
      <DateFieldButtons>
        {params.children}
        {renderCommentsButton(field)}
      </DateFieldButtons>
    );
  }

  function setProposalNameValue(newNameValue: string) {
    setIsEditingName(false);
    const projectName = new ProjectName(newNameValue);
    proposalBuilder.setName(projectName);
    onChange();
  }

  function setProposalDescriptionValue(newDescriptionValue: string) {
    setIsEditingDescription(false);
    const projectDescription = new ProjectDescription(newDescriptionValue);
    proposalBuilder.setDescription(projectDescription);
    onChange();
  }

  if (activeTab !== ProposalFieldCategory.Details) return null;
  const issues = props.issues ?? ProposalIssues.fromBuilder(proposalBuilder);

  return (
    <ProposalContainer>
      <GeneralSection>
        <ClientInput
          disabled={
            disableEditing ||
            isSelectingClient ||
            clientSelectorAnchor !== undefined
          }
          label={
            clientName
              ? "Client Representative"
              : "Select Client Representative"
          }
          error={issues.entries.some((entry) => entry.field === ProposalField.Client)}
          helperText={issues.entries.filter((entry) => entry.field === ProposalField.Client)
              .map((entry) => entry.description).join("\n")}
          required
          onClick={handleSelectClientClicked}
          value={clientName}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {!disableEditing && clientName && (
                    <Tooltip title="Remove Client">
                      <span>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            proposalBuilder.setClient();
                            setClientName("");
                            onChange();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              ),
            },
            inputLabel: {
              shrink: clientName !== "",
            },
          }}
        />
        <NameTextField
          label="Proposal Name"
          required={true}
          value={nameValue}
          error={issues.entries.some((entry) => entry.field.isEqualTo(ProposalField.Name))}
          helperText={issues.entries.filter((entry) => entry.field.isEqualTo(ProposalField.Name))
              .map((entry) => entry.description).join("\n")}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "name",
                    isEditingName,
                    setIsEditingName,
                    setProposalNameValue
                  )}
                  {renderCommentsButton(ProposalField.Name)}
                </>
              ),
            },
          }}
          onFocus={() => setIsEditingName(true)}
          onBlur={(event) => {
            if(event.relatedTarget?.getAttribute("datatype") === "cancel") return;
            setProposalNameValue(nameValue)
          }}
          onChange={(event) => setNameValue(event.target.value)}
          onKeyDown={(event) => {
            if (!isEditingName) return;
            if (event.key === "Enter" || event.key === "Tab") {
              setProposalNameValue(nameValue);
            } else if (event.key === "Escape") {
              setNameValue(proposalBuilder.currentSpec.name?.value ?? "");
              setIsEditingName(false);
            }
          }}
        />
        <DescriptionTextField
          label="Description"
          required={true}
          multiline={true}
          rows={3}
          value={descriptionValue}
          error={issues.entries.some((entry) => entry.field.isEqualTo(ProposalField.Description))}
          helperText={issues.entries.filter((entry) => entry.field.isEqualTo(ProposalField.Description))
              .map((entry) => entry.description).join("\n")}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "description",
                    isEditingDescription,
                    setIsEditingDescription,
                    setProposalDescriptionValue
                  )}
                  {renderCommentsButton(ProposalField.Description)}
                </>
              ),
            },
          }}
          onBlur={(event) => {
            if(event.relatedTarget?.getAttribute("datatype") === "cancel") return;
            setProposalDescriptionValue(descriptionValue)
          }}          onFocus={() => setIsEditingDescription(true)}
          onChange={(event) => setDescriptionValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Tab") {
              setProposalDescriptionValue(descriptionValue);
            } else if (event.key === "Escape") {
              setDescriptionValue(
                proposalBuilder.currentSpec.description?.value ?? ""
              );
              setIsEditingDescription(false);
            } else {
              setIsEditingDescription(true);
            }
          }}
        />
      </GeneralSection>
      {!isRequesting && (
        <DateSection>
          <NegotiableCheckbox>
            <FormControlLabel
              label="Negotiable"
              control={
                <Checkbox
                  color="primary"
                  checked={isNegotiable}
                  disabled={disableEditing}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    setIsNegotiable(checked);
                    proposalBuilder?.setNegotiable(checked);
                    onChange();
                  }}
                />
              }
            />
          </NegotiableCheckbox>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingResponseDueBy ? (
                    <>
                      <span>Set a response due date?</span>
                      <span>
                        {renderCommentsButton(ProposalField.ResponseDueBy)}
                      </span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    disabled={disableEditing}
                    checked={isUsingResponseDueBy}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingResponseDueBy(checked);
                      const newDate = checked ? new Date(moment()) : undefined;
                      setResponseDueBy(newDate?.value ?? null);
                      proposalBuilder?.setResponseDueBy(newDate);
                      onChange();
                    }}
                  />
                }
              />
            </span>
            {isUsingResponseDueBy && (
              <DatePicker
                label="Response Due Date"
                disablePast
                disabled={disableEditing}
                value={responseDueBy}
                minDate={moment()}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.ResponseDueBy),
                }}
                slotProps={{
                  textField: {
                    error: issues.entries.some((entry) => entry.field.isEqualTo(ProposalField.ResponseDueBy)),
                    helperText:
                      issues.entries.filter((entry) => entry.field.isEqualTo(ProposalField.ResponseDueBy))
                        .map((entry) => entry.description).join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  setResponseDueBy(date);
                  proposalBuilder?.setResponseDueBy(
                    date ? new Date(date) : undefined
                  );
                  onChange();
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingStartDate ? (
                    <>
                      <span>Set a start date?</span>
                      <span>
                        {renderCommentsButton(ProposalField.StartDate)}
                      </span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    checked={isUsingStartDate}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingStartDate(checked);
                      const newDate = checked ? new Date(responseDueBy ?? moment()) : undefined;
                      setStartDate(newDate?.value ?? null);
                      proposalBuilder?.setStartDate(newDate);
                      onChange();
                    }}
                  />
                }
              />
            </span>

            {isUsingStartDate && (
              <DatePicker
                label="Start Date"
                disablePast
                disabled={disableEditing}
                value={startDate}
                minDate={responseDueBy ?? moment()}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.StartDate),
                }}
                slotProps={{
                  textField: {
                    error: issues.entries.some((entry) => entry.field.isEqualTo(ProposalField.StartDate)),
                    helperText:
                      issues.entries.filter((entry) => entry.field.isEqualTo(ProposalField.StartDate))
                        .map((entry) => entry.description).join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  setStartDate(date ?? null);
                  proposalBuilder?.setStartDate(
                    date ? new Date(date) : undefined
                  );
                  onChange();
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingEndDate ? (
                    <>
                      <span>Set a end date?</span>
                      <span>{renderCommentsButton(ProposalField.EndDate)}</span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    checked={isUsingEndDate}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingEndDate(checked);
                      const newDate = checked ? new Date(startDate ?? moment()) : undefined;
                      setEndDate(newDate?.value ?? null);
                      proposalBuilder?.setEndDate(newDate);
                      onChange();
                    }}
                  />
                }
              />
            </span>
            {isUsingEndDate && (
              <DatePicker
                label="End Date"
                disablePast
                disabled={disableEditing}
                value={endDate}
                minDate={getMinValidDate(
                  startDate ?? responseDueBy ?? moment()
                )}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.EndDate),
                }}
                slotProps={{
                  textField: {
                    error: issues.entries.some((entry) => entry.field.isEqualTo(ProposalField.EndDate)),
                    helperText:
                      issues.entries.filter((entry) => entry.field.isEqualTo(ProposalField.EndDate))
                        .map((entry) => entry.description).join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  setEndDate(date ?? null);
                  proposalBuilder?.setEndDate(
                    date ? new Date(date) : undefined
                  );
                  onChange();
                }}
              />
            )}
          </DateContainer>
        </DateSection>
      )}
      <ClientSelector
        clientUserId={proposalBuilder.currentSpec.client?.userId}
        popoverAnchor={clientSelectorAnchor}
        onPopoverClose={() => {
          setClientSelectorAnchor(undefined);
          setIsSelectingClient(false);
          onSelectingClientChange(false);
        }}
        onClientUpdated={handleClientUpdated}
      />
    </ProposalContainer>
  );
}
