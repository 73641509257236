import Text from "common/values/text/text";

export default class FeeScheduleCategoryDescription extends Text {
    constructor(value: string) {
        super(value);

    }
    public get isEmpty(): boolean {
        return this.value === '';
    }
    public isEqualTo(other: Text | null): boolean {
        if(!(other instanceof FeeScheduleCategoryDescription)) return false;
        return super.isEqualTo(other);
    }
    
    public override clone(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription(this.value);
    }

    public static get Prototype(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription('Prototype');
    }

    public override fromObject(obj: any): Text | null {
        return new FeeScheduleCategoryDescription(obj);
    }
    public toJSON(): any {
        return this._value;
    }
}