import FilterListIcon from '@mui/icons-material/FilterList';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Chip, Container, Fab, Popover, Theme, Typography, useMediaQuery, useScrollTrigger, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDialog } from 'app/providers/dialog';
import { DialogProps } from 'common/components/dialog';
import * as Constants from 'common/helpers/constants';
import Guid from 'common/values/guid/guid';
import Individual from 'marketplace/entities/individual/individual';
import { Filters } from 'marketplace/helpers/filters';
import ViewCompanyProfile from 'marketplace/values/company-profile/view/view-company-profile';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import ViewTeamProfile from 'marketplace/values/team-profile/view/view-team-profile';
import { ArrayChip, RangeChip, TextChip } from 'marketplace/view/filter-chip';
import { FilterChips, FilterChipType } from 'marketplace/view/filter-chips';
import FilterList from 'marketplace/view/filter-list';
import Results from 'marketplace/view/results';
import SearchBar from 'marketplace/view/search-bar';
import SortButton from 'marketplace/view/sort-button';
import SortDropdown from 'marketplace/view/sort-dropdown';
import React, { useEffect } from 'react';
import { Params, useLoaderData, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSession } from 'users/session/session-context';

const PageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${Constants.elementHeight.appBar}px - ${Constants.elementHeight.footer}px)`,
}));
const SearchContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  backgroundColor: 'antiquewhite',
  borderRadius: theme.spacing(0.5),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(2),
  position: 'sticky',
  width: '100%',
  zIndex: 10
}));
const MainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1
}));
const ResultsContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginTop: 0
  }
}));
const ResultsPane = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    overflowX: 'hidden',
    overflowY: 'visible',
    padding: theme.spacing(0)
  },
  backgroundColor: '#FAFAFA',
  flex: 1,
  height: '100%',
  maxHeight: `calc(100vh - ${Constants.elementHeight.appBar}px - ${Constants.elementHeight.marketplaceSearchBar}px - ${Constants.elementHeight.footer}px)`,
  overflowY: 'auto',
  padding: theme.spacing(0, 1)
}));
const ChipContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(1)
  }
}));
const SearchFilterChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1)
}));
const TopBar = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backdropFilter: 'blur(7px) saturate(200%)',
  backgroundColor: 'rgba(250, 250, 250, 0.87)',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  position: 'sticky',
  top: 0,
  zIndex: 10
}));
const FilterButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(1)
  }
}));
const FilterListComponent = styled(FilterList)(({ theme }) => ({
  position: 'sticky',
  top: (
    Constants.elementHeight.appBar + Constants.elementHeight.marketplaceSearchBar
  )
}));
const FilterContainer = styled('div')(({ theme }) => ({
  height: '100%',
  maxHeight: `calc(100vh - ${Constants.elementHeight.appBar}px - ${Constants.elementHeight.marketplaceSearchBar}px - ${Constants.elementHeight.footer}px)`,
  width: theme.spacing(38),
  overflowX: 'hidden',
  overflowY: 'auto'
}));
const BackToTopButton = styled(Fab)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    bottom: theme.spacing(4)
  },
  [theme.breakpoints.down('xl')]: {
    right: theme.spacing(1)
  },
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(4)
}));
const ShowResultsButton = styled(Button)(({ theme }) => ({
  display: 'block',
  margin: 'auto'
}));

type MarketplaceProps = {
  embedded?: boolean;
  selectingLeader?: boolean;
  selectingMember?: boolean;
  onTeamMemberSelected?: (teamMember: Individual) => void;
};

export default function Marketplace(props: Readonly<MarketplaceProps>) {
  const { embedded, selectingLeader, selectingMember, onTeamMemberSelected } = props;

  const resultsRef = React.useRef<HTMLDivElement>(null);
  const filtersRef = React.useRef<FilterChips>(new FilterChips());

  const [searchTerm, setSearchTerm] = React.useState('');
  const [filters, setFilters] = React.useState<FilterChips>(filtersRef.current);
  const [sortBy, setSortBy] = React.useState('relevance');
  const [category, setCategory] = React.useState('all');
  const [numResults, setNumResults] = React.useState(0);
  const [filterPopoverAnchor, setFilterPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);

  const { openDialog, closeDialog, closeAllDialogs } = useDialog();

  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const routeParams = useLoaderData() as Params<string>;

  const session = useSession();

  const scrollTrigger = useScrollTrigger({
    target: resultsRef.current ?? undefined,
    disableHysteresis: true,
    threshold: 100
  });

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  useEffect(() => {
    if (!session.user?.companyEntityId) return;

    function parseQueryString() {
      const filters: FilterChipType[] = Filters;

      if (searchParams.has('query')) setSearchTerm(searchParams.get('query') ?? '');
      if (searchParams.has('category')) setCategory(searchParams.get('category') ?? 'all');
      if (searchParams.has('sortby')) setSortBy(searchParams.get('sortby') ?? 'relevance');

      searchParams.forEach((filterValue, filterName) => {
        let parsedFilter = filters.find(filter => filter.filterName === filterName);

        if (!parsedFilter) return;

        if (filterName === 'query' && filterValue === '') {
          handleFilterDisabled(parsedFilter);
          return;
        }

        if (filterValue === '') {
          parsedFilter.value = [];
        } else {
          parsedFilter.value = filterValue.split(',');
        }

        parsedFilter.enable();

        handleFilterChanged(parsedFilter);
      });

      setFilters(new FilterChips(filters));
    }

    filtersRef.current.addRange(Filters);
    setFilters(new FilterChips(filtersRef.current.chips));
    parseQueryString();
  }, [searchParams]);

  useEffect(() => {
    if (!session.user?.companyEntityId) return;

    if (!routeParams?.type || !routeParams?.id) return;
    const type = routeParams.type;
    const id = routeParams.id;

    loadRouteProfile(type, id);

    function loadRouteProfile(type?: string, id?: string) {
      if (!type || !id) return;

      const guidId = new Guid(id);
      const parentPath = new URL(".", window.location.origin + window.location.pathname);

      if (type === 'individual') {
        openDialog({
          component: <ViewIndividualProfile individualId={guidId} />,
          MuiProps: {
            fullWidth: true,
            maxWidth: 'lg'
          },
          contentSxProps: {
            display: "flex",
          },
          titleStyle: {
            position: "absolute",
            right: 0,
            top: 0,
          },
        },
        () => navigate(parentPath.pathname)
        );
      } else if (type === 'team') {
        openDialog({
          component: <ViewTeamProfile teamId={guidId} />,
          MuiProps: {
            fullWidth: true,
            maxWidth: 'lg'
          },
          contentSxProps: {
            display: "flex",
          },
          titleStyle: {
            position: "absolute",
            right: 0,
            top: 0,
          }
        },
        () => navigate(parentPath.pathname)
        );
      } else if (type === 'company') {
        openDialog({
          component: <ViewCompanyProfile companyId={guidId} />,
          MuiProps: {
            fullWidth: true,
            maxWidth: 'lg'
          },
          contentSxProps: {
            display: "flex",
          },
          titleStyle: {
            position: "absolute",
            right: 0,
            top: 0,
          }
        },
        () => navigate(parentPath.pathname)
        );
      }
    }
  }, [routeParams]);

  function handleFilterChanged(updatedFilter: FilterChipType) {
    if (!updatedFilter.value) return;

    const targetChip = filtersRef.current.findByName(updatedFilter.filterName);

    if (!targetChip) return;

    if (targetChip instanceof ArrayChip || targetChip instanceof RangeChip) {
      filtersRef.current.updateValue(updatedFilter.filterName, updatedFilter.value as string[]);
    } else {
      filtersRef.current.updateValue(updatedFilter.filterName, updatedFilter.value as string);
    }

    setFilters(new FilterChips(filtersRef.current.chips));

    let params = searchParams;
    params.set(updatedFilter.filterName, updatedFilter.value.toString());
    setSearchParams(params);
  }

  function handleFilterEnabled(updatedFilter: FilterChipType) {
    filtersRef.current.enable(updatedFilter.filterName);
    setFilters(new FilterChips(filtersRef.current.chips));
  }

  function handleFilterDisabled(updatedFilter: FilterChipType) {
    filtersRef.current.disable(updatedFilter.filterName);
    setFilters(new FilterChips(filtersRef.current.chips));

    let params = searchParams;
    params.delete(updatedFilter.filterName);
    setSearchParams(params);

    if (updatedFilter.filterName === 'query') {
      setSearchTerm('');
    }
  }

  function handleCategoryChanged(category: { id: any; name: any; description?: string; }) {
    setNumResults(0);
    setSearchTerm('');
    setCategory(category.id);
    setSortBy('relevance');

    filtersRef.current.disableAll();
    filtersRef.current.addRange(Filters);

    if (category.id !== 'all') {
      filtersRef.current.enable(category.id);
      const updatedFilter = new TextChip('category', category.name);
      handleFilterChanged(updatedFilter);
    }

    setFilters(new FilterChips(filtersRef.current.chips));

    let params = searchParams;
    params.set('category', category.id);
    params.set('pageIndex', '0');
    setSearchParams(params);
  }

  function handleSearch(term: string = '') {
    setSearchTerm(term);
    filtersRef.current.updateValue('query', term);
    filtersRef.current.enable('query');
    setFilters(new FilterChips(filtersRef.current.chips));

    let params = searchParams;
    params.set('query', term);
    setSearchParams(params);

    navigate({ search: params.toString() },);
  }

  function handleSort(sortField: string) {
    setSortBy(sortField);
    let params = searchParams;
    params.set('sortby', sortField);
    setSearchParams(params);
  }

  function openFilterDialog() {
    const dialogProps: DialogProps = {
      MuiProps: {
        fullScreen: true,
        keepMounted: true,
        disablePortal: true
      },
      title: (
        <ShowResultsButton
          size="large"
          variant="contained"
          disableElevation={true}
          color="primary"
          onClick={closeDialog}>
          Show {numResults} Result{numResults === 1 ? null : 's'}
        </ShowResultsButton>
      ),
      component: (
        <FilterList
          dialog={true}
          filters={filters}
          onChange={handleFilterChanged}
          onAdded={handleFilterEnabled}
          onRemoved={handleFilterDisabled}
        />
      )
    };
    openDialog(dialogProps);
  }

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar
          searchTerm={searchTerm}
          onCategoryChanged={handleCategoryChanged}
          onSearch={handleSearch}
          onSearchCleared={() => {
            handleFilterDisabled(filtersRef.current.findByName('query')!);
            handleSearch();
          }}
        />
      </SearchContainer>
      <MainContent>
        {xlUp && (
          <FilterContainer>
            <FilterListComponent
              filters={filters}
              dialog={false}
              onChange={handleFilterChanged}
              onAdded={handleFilterEnabled}
              onRemoved={handleFilterDisabled}
            />
          </FilterContainer>
        )}
        <ResultsPane ref={resultsRef}>
          <TopBar>
            <ChipContainer>
              {filters.chips.every((chip: FilterChipType) => !chip.enabled) && (
                <SearchFilterChip label="No Active Filters" disabled size={mdDown ? 'small' : 'medium'} />
              )}
              {filters.chips.map((chip: FilterChipType) => (
                <SearchFilterChip
                  key={chip.filterName}
                  label={chip.label}
                  size={mdDown ? 'small' : 'medium'}
                  style={{ display: !chip.enabled ? 'none' : 'inline-flex' }}
                  onDelete={() => handleFilterDisabled(chip)}
                />
              ))}
            </ChipContainer>
            {xlUp && <SortDropdown sortBy={sortBy} onSort={handleSort} />}
            {xlDown && (
              <FilterButtons>
                <Button
                  size="large"
                  color="primary"
                  startIcon={<FilterListIcon />}
                  onClick={(event) => mdUp ?
                    setFilterPopoverAnchor(event.currentTarget) :
                    openFilterDialog()
                  }>
                  Filters
                </Button>
                <Popover
                  anchorEl={filterPopoverAnchor}
                  open={Boolean(filterPopoverAnchor)}
                  onClose={() => setFilterPopoverAnchor(null)}>
                  <FilterList
                    dialog={true}
                    filters={filters}
                    onChange={handleFilterChanged}
                    onAdded={handleFilterEnabled}
                    onRemoved={handleFilterDisabled}
                  />
                </Popover>
                <SortButton sortBy={sortBy} onSort={handleSort} />
              </FilterButtons>
            )}
          </TopBar>
          <ResultsContainer>
            <Results
              session={session}
              embedded={embedded}
              selectingTeamLeader={selectingLeader}
              selectingTeamMember={selectingMember}
              category={category}
              searchTerm={searchTerm}
              filters={filters.toDictionary()}
              sortBy={sortBy}
              navigate={navigate}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              onResults={(results: number | undefined) => setNumResults(results ?? 0)}
              onTeamMemberSelected={onTeamMemberSelected}
            />
            <Zoom in={scrollTrigger}>
              <BackToTopButton
                color="primary"
                size="medium"
                variant="extended"
                onClick={() => {
                  if (resultsRef.current) resultsRef.current.scrollTop = 0;
                }}>
                <UpIcon />
                Back To Top
              </BackToTopButton>
            </Zoom>
          </ResultsContainer>
        </ResultsPane>
      </MainContent>
    </PageContainer>
  );
}
