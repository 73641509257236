import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  styled,
  Switch,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { CanceledError } from "axios";
import { AccountType } from "common/values/account-type/account-type";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { useSession } from "users/session/session-context";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import Team from "work/values/team/team";
import { TeamMember } from "work/values/team/team-member";
import EditTeam from "work/values/team/view/edit-team";

const ListContainer = styled(Grid)(({ theme }) => ({
  minHeight: "20rem",
}));
const TemplateContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));
const TemplateList = styled(List)(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxHeight: "25rem",
  minWidth: "20rem",
  overflowY: "auto",
}));
const TemplateLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(2),
}));
const TeamSelect = styled(EditTeam)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  marginLeft: theme.spacing(2),
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
}));

type TeamTabProps = {
  entityId: Guid;
  activeTab: ProposalFieldCategory;
  proposal?: Proposal;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableCommenting: boolean;
  disableEditing?: boolean;
  commentForums: Forum[];
  onChange: () => void;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
};

export default function TeamTab(props: Readonly<TeamTabProps>) {
  const {
    entityId,
    activeTab,
    proposal,
    proposalBuilder,
    disableCommenting,
    disableEditing,
    commentForums,
    onChange,
    onCommentsClicked
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [templateTeams, setTemplateTeams] = React.useState<TeamTemplate[]>([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<Guid[]>(
    []
  );
  const [teamRestrictedValue, setTeamRestrictedValue] =
    React.useState<boolean>();
  const [selectedLeader, setSelectedLeader] = React.useState<TeamMember | null>(
    null
  );
  const [selectedMembers, setSelectedMembers] = React.useState<TeamMember[]>(
    []
  );

  const confirm = useConfirmDialog();
  const session = useSession();
  const individualAPIService = useMemo(() => {
    return new IndividualAPIService(session)
  }, [
    session,
  ]);
  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  useEffect(() => {
    if (activeTab !== ProposalFieldCategory.Team) return;
    let abortController = new AbortController();
    getTemplateTeams(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [activeTab]);

  useEffect(() => {
    initializeTeam();
  }, [
    proposal?.teamRestricted,
    proposal?.details?.team?.leader,
    proposalBuilder.currentSpec.team,
  ]);

  async function initializeTeam() {
    if (
      selectedTemplateIds.length > 0 ||
      selectedLeader ||
      selectedMembers.length > 0
    )
      return;

    const proposalTeam = proposalBuilder?.currentSpec.team;

    if (proposalTeam?.leader) await initializeFromTeam(proposalTeam);
    else if (proposal?.client) setDefaultProposalTeamLeader();

    const proposalBuilderTeamRestricted =
      proposalBuilder?.currentSpec.teamRestricted?.value;
    const proposalTeamRestricted = proposal?.teamRestricted.value;
    if (proposalTeamRestricted) setTeamRestrictedValue(proposalTeamRestricted);
    else if (proposalBuilderTeamRestricted)
      setTeamRestrictedValue(proposalBuilderTeamRestricted);

    let initialSelectedTemplateIds: Guid[] = [];
    if(session.context?.viewingAsVendor) {
      initialSelectedTemplateIds = proposalBuilder?.currentSpec.vendorTeamTemplateIds.map((id) => id.clone()) ?? [];
    }
    else {
      initialSelectedTemplateIds = proposalBuilder?.currentSpec.clientTeamTemplateIds.map((id) => id.clone()) ?? [];
    }
    setSelectedTemplateIds(initialSelectedTemplateIds);
  }

  async function initializeFromTeam(team: Team) {
    const leaderUserId = team.leader?.userId;
    const memberUserIds = [...team.memberUserIds];

    if(leaderUserId) memberUserIds.push(leaderUserId);

    const individuals = await getTeamUserProfileInfo(memberUserIds);
    const teamMembers: TeamMember[] = individuals.map((i) => ({
      individualId: i.id,
      userId: i.userId ?? undefined,
      entityId: i.company?.entityId,
      firstName: i.profile?.firstName ?? "",
      lastName: i.profile?.lastName ?? "",
      avatarId: i.profile?.avatarId,
    }));
    const leader = teamMembers.find((m) => m.userId?.isEqualTo(leaderUserId));
    const selectedTeamMembers = teamMembers.filter(
      (m) => !m.userId?.isEqualTo(leaderUserId)
    );
    if (leader) setSelectedLeader(leader);
    setSelectedMembers(selectedTeamMembers);
  }

  async function getTeamUserProfileInfo(
    userIds: Guid[]
  ): Promise<Individual[]> {
    try {
      const abortController = new AbortController();
      return await individualAPIService.getUsersProfileInfo(userIds, abortController);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  function setDefaultProposalTeamLeader() {
    if (
      !proposalBuilder.currentSpec.team?.leader &&
      session.accountType === AccountType.Vendor &&
      session.context?.isVendorRepresentative &&
      session.user?.id &&
      session.user.companyEntityId &&
      session.user.individualId &&
      session.user.asVendorRepresentative
    ) {
      const defaultTeam = new Team(session.user.asVendorRepresentative, []);
      proposalBuilder.setTeam(defaultTeam);

      const leader: TeamMember = {
        individualId: session.user.individualId,
        userId: session.user.id,
        entityId: session.user.companyEntityId,
        firstName: session.user.name.firstName ?? "",
        lastName: session.user.name.lastName ?? "",
      };
      setSelectedLeader(leader);
      onChange();
    }
  }

  async function getTemplateTeams(
    abortController: AbortController
  ): Promise<void> {
    if (!entityId || !session.user?.isCompanyManager) return;

    try {
      setIsLoading(true);
      const teamTemplateService = new TeamTemplateAPIService(session);
      const templates = await teamTemplateService.getTeamTemplates(
        entityId,
        session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        abortController
      );
      setTemplateTeams(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function updateTeamOnProposalBuilder(
    leader?: TeamMember,
    members?: TeamMember[]
  ) {

    const team = new Team(
      leader?.entityId ? new EntityVendorRepresentative(leader.userId, leader.entityId) : undefined,
      (members
        ?.filter((m) => m.userId && !m.userId.isEqualTo(leader?.userId))
        .map((m) => m.userId) as Guid[]) ?? []
    );
    proposalBuilder.setTeam(team);
    onChange();
  }
  

  function handleTemplateExpanded(template: TeamTemplate) {
    const templates = [...templateTeams];
    let targetTemplate = templates.find((team) =>
      team.id?.isEqualTo(template.id)
    );
    template.isExpanded = !targetTemplate?.isExpanded;

    setTemplateTeams(templates);
  }

  async function handleTemplateSelectionToggled(
    template: TeamTemplate
  ): Promise<void> {
    try {
      if (!template.id) {
        return Promise.reject(new Error("No template selected"));
      }
      let updatedSelectedTemplateIds = selectedTemplateIds;

      const isAlreadySelected = selectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );

      if (isAlreadySelected) {
        updatedSelectedTemplateIds = selectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        updatedSelectedTemplateIds.push(template.id);

        const leader = await updateLeaderFromTemplate(template);
        const members = updateTeamMembersFromTemplate(template);

        updateTeamOnProposalBuilder(leader, members);
        setSelectedLeader(leader);
        setSelectedMembers(members);
      }

      if (session.context?.viewingAsVendor) {
        proposalBuilder.setVendorTeamTemplateIds(updatedSelectedTemplateIds);
      } else {
        proposalBuilder.setClientTeamTemplateIds(updatedSelectedTemplateIds);
      }

      setSelectedTemplateIds(updatedSelectedTemplateIds);
      onChange();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to apply template. Please try again", {
        variant: "error",
      });
    }
  }

  function updateTeamMembersFromTemplate(template: TeamTemplate) {
    const templateMembers: TeamMember[] = template.members.map(
      (member: Individual) => ({
        individualId: member.id,
        userId: member.userId ?? undefined,
        entityId: member.entityId,
        firstName: member.profile?.firstName ?? "",
        lastName: member.profile?.lastName ?? "",
        avatarId: member.profile?.avatarId,
      })
    );

    const members = _.uniqWith(
      [...templateMembers, ...selectedMembers],
      (a, b) =>
        (!a.userId && !b.userId) || (a.userId?.isEqualTo(b.userId) ?? false)
    );
    return members;
  }

  async function updateLeaderFromTemplate(
    template: TeamTemplate
  ): Promise<TeamMember> {
    let leader: TeamMember = {
      individualId: template.leader.id,
      userId: template.leader.userId ?? undefined,
      entityId:
        template.leader.entityId ??
        template.leader.profile?.entityId ??
        template.leader.company?.entityId, // TODO: investigate why this is necessary
      firstName: template.leader.profile?.firstName ?? "",
      lastName: template.leader.profile?.lastName ?? "",
      avatarId: template.leader.profile?.avatarId,
    };
    if (!selectedLeader) return leader;

    const templateLeaderAlreadySelected = selectedLeader.userId?.isEqualTo(
      template.leader.userId
    );
    if (templateLeaderAlreadySelected) {
      return leader;
    } else {
      const response = await confirm({
        title: "Replace leader?",
        message:
          "Do you want to replace the current team leader with the one from the template?",
        okButtonText: "Replace",
        cancelButtonText: "Keep Current Leader",
      });

      if (response !== ConfirmResponse.Ok) {
        leader = selectedLeader;
      }
    }
    return leader;
  }
  function handleTeamLeaderUpdated(
    leader: TeamMember | Individual | null
  ): void {
    if (getIsMemberAlreadySelected(leader)) return;
    if (!leader) {
      setSelectedLeader(null);
      updateTeamOnProposalBuilder(undefined, selectedMembers);
      return;
    }

    let teamLeader: TeamMember;
    if (leader instanceof Individual) {
      teamLeader = {
        individualId: leader.id,
        userId: leader.userId ?? undefined,
        entityId:
          leader.entityId ??
          leader.profile?.entityId ??
          leader.company?.entityId, // TODO: investigate why this is necessary
        firstName: leader.profile?.firstName ?? "",
        lastName: leader.profile?.lastName ?? "",
        avatarId: leader.profile?.avatarId,
      };
    } else {
      teamLeader = leader;
    }
    if (!teamLeader.entityId) {
      enqueueSnackbar("Selected leader is not associated with an entity", {
        variant: "error",
      });
      return;
    }

    setSelectedLeader(teamLeader);
    updateTeamOnProposalBuilder(teamLeader, selectedMembers);
  }

  function handleTemplateLeaderToggled(leader: TeamMember | Individual): void {
    const isAlreadySelected = getIsMemberAlreadySelected(leader);
    let teamLeader: TeamMember | null;

    if (isAlreadySelected) {
      teamLeader = null;

    } else {

      if (leader instanceof Individual) {
        teamLeader = {
          individualId: leader.id,
          userId: leader.userId ?? undefined,
          entityId:
            leader.entityId ??
            leader.profile?.entityId ??
            leader.company?.entityId, // TODO: investigate why this is necessary
          firstName: leader.profile?.firstName ?? "",
          lastName: leader.profile?.lastName ?? "",
          avatarId: leader.profile?.avatarId,
        };
      } else {
        teamLeader = leader;
      }

      if (!teamLeader.entityId) {
        enqueueSnackbar("Selected leader is not associated with an entity", {
          variant: "error",
        });
        return;
      }
    }

    setSelectedLeader(teamLeader);
    updateTeamOnProposalBuilder(teamLeader ?? undefined, selectedMembers);
  }

  function handleTeamMemberAdded(member: TeamMember | Individual) {
    const isAlreadySelected = getIsMemberAlreadySelected(member);

    if (isAlreadySelected) {
      handleTeamMemberRemoved(member.userId);
    } else {
      let teamMember: TeamMember;

      if (member instanceof Individual) {
        teamMember = {
          individualId: member.id,
          userId: member.userId ?? undefined,
          entityId:
            member.entityId ??
            member.profile?.entityId ??
            member.company?.entityId,
          firstName: member.profile?.firstName ?? "",
          lastName: member.profile?.lastName ?? "",
          avatarId: member.profile?.avatarId,
        };
      } else {
        teamMember = member;
      }

      let updatedMembers = [...selectedMembers, teamMember];
      setSelectedMembers(updatedMembers);
      updateTeamOnProposalBuilder(selectedLeader ?? undefined, updatedMembers);
    }
  }

  function getIsMemberAlreadySelected(
    member: TeamMember | Individual | null
  ): boolean {
    if (!member) return false;
    return (
      (selectedLeader?.userId?.isEqualTo(member.userId) ?? false) ||
      selectedMembers.some((m) => m.userId?.isEqualTo(member.userId))
    );
  }

  function handleTeamMemberRemoved(userId?: Guid) {
    let updatedMembers: TeamMember[] = [];
    if (userId) {
      updatedMembers = selectedMembers.filter(
        (m) => !m.userId?.isEqualTo(userId)
      );
    }
    setSelectedMembers(updatedMembers);
    updateTeamOnProposalBuilder(selectedLeader ?? undefined, updatedMembers);
  }

  async function handleMarketplaceTeamSelected(team: MarketplaceTeam) {
    if (selectedLeader !== undefined || selectedMembers.length > 0) {
      const response = await confirm({
        title: "Replace Team?",
        message: "Are you sure you want to replace the current team?",
        okButtonText: "Replace",
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    if (!team.leader.profile?.firstName || !team.leader.profile?.lastName) {
      throw new Error("Team leader profile is undefined");
    }

    const leader: TeamMember = {
      individualId: team.leader.id,
      userId: team.leader.userId ?? undefined,
      firstName: team.leader.profile.firstName,
      lastName: team.leader.profile.lastName,
      entityId:
        team.leader.entityId ??
        team.leader.profile?.entityId ??
        team.leader.company?.entityId,
    };
    const members: TeamMember[] = team.memberships.map((member) => {
      if (!member.profile) throw new Error("Team member profile is undefined");

      return {
        individualId: member.id,
        userId: member.userId ?? undefined,
        firstName: member.profile.firstName,
        lastName: member.profile.lastName,
        entityId:
          member.entityId ??
          member.profile?.entityId ??
          member.company?.entityId,
      };
    });

    setSelectedLeader(leader);
    setSelectedMembers(members);
    updateTeamOnProposalBuilder(leader, members);
  }

  function renderTemplates() {
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateTeams.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4} />
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateTeams.map((template: TeamTemplate) => {
          const isSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );

          return (
            <div key={template.id?.toString()}>
              <ListItem>
                <ListItemIcon>
                  <Tooltip
                    title={
                      !isSelected ? "Select Template" : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={isSelected}
                        indeterminate={
                          !isSelected &&
                          (template.userIsReferenced(selectedLeader?.userId) ||
                            selectedMembers.some((member) =>
                              template.userIsReferenced(member.userId)
                            ))
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{template.name.value}</ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip
                    title={`${template.isExpanded ? "Hide" : "Show"} Members`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded !== false ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                sx={{ backgroundColor: "#FAFAFA" }}
              >
                <Divider />
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Leader</ListSubheader>}
                >
                  {renderTemplateLeader(template)}
                </List>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Members</ListSubheader>}
                >
                  {renderTemplateMembers(template)}
                </List>
                <Divider />
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateLeader(template: TeamTemplate) {
    return (
      <ListItemButton key="teamLeader">
        <ListItemText>{template.leader.getFullName()}</ListItemText>
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            color="primary"
            checked={
              selectedLeader?.userId?.isEqualTo(template?.leader?.userId) ??
              false
            }
            tabIndex={-1}
            disableRipple
            onClick={() => handleTemplateLeaderToggled(template?.leader)}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  }

  function renderTemplateMembers(template: TeamTemplate) {
    return template?.members?.map((member: Individual) => (
      <ListItemButton
        key={member.userId?.value ?? Guid.generate().value}
        onClick={() => handleTeamMemberAdded(member)}
      >
        <ListItemText
          style={{ whiteSpace: "pre-line" }}
          primary={`${member.profile?.firstName} ${member.profile?.lastName}`}
        />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            color="primary"
            checked={selectedMembers?.some((m) =>
              m.userId?.isEqualTo(member.userId)
            )}
            tabIndex={-1}
            disableRipple
            onClick={() => {
              if (
                selectedMembers.some((m) => m.userId?.isEqualTo(member.userId))
              ) {
                handleTeamMemberRemoved(member.userId);
              } else {
                handleTeamMemberAdded(member);
              }
            }}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    ));
  }

  function handleTeamRestrictionChanged(checked: AHBoolean) {
    setTeamRestrictedValue(checked.value);
    proposalBuilder.setTeamRestricted(checked);
    onChange();
  }

  if (activeTab !== ProposalFieldCategory.Team) return null;

  return (
    <>
      <HeadControls>
        <FormControlLabel
          checked={teamRestrictedValue}
          control={
            <Switch
              checked={teamRestrictedValue}
              onChange={(_event, checked) =>
                handleTeamRestrictionChanged(new AHBoolean(checked))
              }
              color="primary"
            />
          }
          label={
            teamRestrictedValue ? (
              <strong>Team Restricted</strong>
            ) : (
              "Team Not Restricted"
            )
          }
        />
      </HeadControls>
      <ListContainer container direction="row">
        {!disableEditing && (
          <>
            <TemplateContainer>
              {renderTemplates()}
              {isLargeDownDisplay && <Divider />}
            </TemplateContainer>
            {!isLargeDownDisplay && (
              <Grid>
                <Divider orientation="vertical" />
              </Grid>
            )}
          </>
        )}
        <Grid size="grow">
          <TeamSelect
            leader={selectedLeader}
            members={selectedMembers}
            commentForums={commentForums}
            disableEditing={disableEditing}
            disableCommenting={disableCommenting}
            onCommentsClicked={(memberId?: Guid, name?: string) => {
              onCommentsClicked(
                memberId
                  ? ProposalField.TeamMember(memberId)
                  : ProposalField.Team,
                name
              );
            }}
            onLeaderUpdated={handleTeamLeaderUpdated}
            onMemberAdded={handleTeamMemberAdded}
            onMemberRemoved={handleTeamMemberRemoved}
            onMarketplaceTeamSelected={handleMarketplaceTeamSelected}
          />
        </Grid>
      </ListContainer>
    </>
  );
}
