import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import SecurityIcon from '@mui/icons-material/Security';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { CanceledError } from 'axios';
import Guid from 'common/values/guid/guid';

import EditCompanyProfile from 'marketplace/values/company-profile/view/edit-company-profile';
import { createMRTColumnHelper, MaterialReactTable, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import SearchUsersAPIRequest from 'users/entities/super-user/api/request-contracts/search-users-api-request';
import SuperUserAPIService from 'users/entities/super-user/api/super-user-api-service';
import AuthEmailAPIService, { AuthResponse } from "users/entities/user/api/auth-email-api-service";
import User from 'users/entities/user/user';
import { useSession } from 'users/session/session-context';

const RowActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap'
}));

export default function UsersAdmin() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const [data, setData] = React.useState<User[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const confirm = useConfirmDialog();
  const session = useSession();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  useEffect(() => {
    let abortController = new AbortController();
    fetchData(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [
    columnFilters,
    query,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const fetchData = async (abortController?: AbortController) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const apiService = new SuperUserAPIService(session)
      const request = new SearchUsersAPIRequest(
        query ?? "",
        pagination.pageIndex, pagination.pageSize,
        sorting.length > 0 ? sorting[0] : undefined
      );
      const pagedUserResponse = await apiService.searchUsers(request, abortController);

      setData(pagedUserResponse.deserializeUsers());
      setRowCount(pagedUserResponse.totalCount);
      setPagination({
        pageIndex: pagedUserResponse.pageIndex,
        pageSize: pagedUserResponse.pageSize,
      });
    } catch (error) {
      if (error instanceof CanceledError) return;
      setWasErrorLoading(true);
      console.error(error);
      return;
    }
    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  async function registerSuperUser(_event: any, user: User) {
    if (!user) return;
    const response = await confirm({
      title: `Register ${user.email.value} as a superuser?`,
      message: "Are you sure you want to register this user as a superuser?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.register(user);
      fetchData();
    } catch (err: any) {
      console.error("Couldn't register superuser");
      enqueueSnackbar("Couldn't register superuser", { variant: 'error' });
    }
  }

  async function unRegisterSuperUser(_event: any, user: User) {
    const response = await confirm({
      title: `Unregister ${user.email.value} as a superuser?`,
      message: "Are you sure you want to unregister this user as a superuser?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.unregister(user);
      fetchData();
    } catch (err: any) {
      console.error("Couldn't unregister superuser");
      enqueueSnackbar("Couldn't unregister superuser", { variant: 'error' });
    }
  }

  async function disableUser(_event: any, user: User) {
    const response = await confirm({
      title: `Disable ${user.email.value}?`,
      message: "Are you sure you want to disable this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    })

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.disableUser(user);
      enqueueSnackbar("Disabled user.", { variant: 'success' });
      fetchData();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't disable user", { variant: 'error' });
    }
  }

  async function enableUser(_event: any, user: User) {
    const response = await confirm({
      title: `Enable ${user.email}?`,
      message: "Are you sure you want to enable this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      await superUserService.enableUser(user);
      enqueueSnackbar("Re-enabled user.", { variant: 'success' });
      fetchData();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't re-enable user", { variant: 'error' });
    }
  }

  async function resetUserPassword(_event: any, data: User | User[]) {
    let user: User;
    if (Array.isArray(data)) {
      user = data[0];
    }
    else {
      user = data;
    }
    const response = await confirm({
      title: `Reset ${user.email}'s password?`,
      message: "Are you sure you want to reset this user's password?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      await AuthEmailAPIService.requestPasswordReset(user.email);
      enqueueSnackbar("Sent password reset email to user", { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't reset user password", { variant: 'error' });
    }
  }

  async function loginAsUser(_event: any, data: User | User[]) {
    let user: User;
    if (Array.isArray(data)) {
      user = data[0];
    }
    else {
      user = data;
    }
    const response = await confirm({
      title: `Login as ${user.email}?`,
      message: "Are you sure you want to login as this user?",
      okButtonText: "Yes",
      cancelButtonText: "No"
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const superUserService = new SuperUserAPIService(session);
      setIsLoading(true);
      const authResponse: AuthResponse = await superUserService.loginAsUser(user);
      session.initializeFromAuthResponse(authResponse);
      navigate('/dashboard', { replace: true });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't login as user", { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const renderCompanyColumn = (user: User) => {
    if (!user.companyId) return null;
    return (
      <Link
        style={{
          color: '#000',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        onClick={() => openDialog({
          component: (
            <EditCompanyProfile
              isVisible={false}
              companyId={user.companyId}
            />
          )
        })
        }>
        {user.companyName}
      </Link>
    );
  }

  const columnHelper = createMRTColumnHelper<User>();
  const columns = [
    columnHelper.accessor((user) => user.email.value, {
      header: "Email",
      id: "email",
      Cell: ({ row }) => (
        <Typography
          variant="body2"
          sx={{
            fontWeight: "500",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.email.value}
        </Typography>
      ),
    }),
    columnHelper.accessor((user) => user.name.firstName, {
      header: "First Name",
      id: "firstName",
    }),
    columnHelper.accessor((user) => user.name.lastName, {
      header: "Last Name",
      id: "lastName",
    }),
    columnHelper.accessor(renderCompanyColumn, {
      header: "Company",
      id: "company",
    })
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableTableHead: true,
    enableRowActions: true,
    enableColumnPinning: true,
    enableGlobalFilter: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] },
      showGlobalFilter: true
    },
    renderRowActions: ({ row, table }) => (
      <RowActionsContainer>
        <Tooltip title={row.original.isSuperUser ? 'Unregister As Superuser' : 'Register As Superuser'}>
          <IconButton
            color={row.original.isSuperUser ? 'error' : 'primary'}
            onClick={(event) => {
              row.original.isSuperUser ?
                unRegisterSuperUser(event, row.original) :
                registerSuperUser(event, row.original)
            }}
          >
            <SecurityIcon style={{ color: row.original.isSuperUser ? 'error' : 'primary' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Force Password Reset">
          <IconButton
            color="primary"
            onClick={(event) => resetUserPassword(event, row.original)}
          >
            <VpnKeyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Login As User">
          <IconButton
            color="secondary"
            onClick={(event) => {
              loginAsUser(event, row.original);
            }}
          >
            <LockOpenIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={row.original.userIsDisabled ? 'Enable User' : 'Disable User'}>
          <IconButton
            color="error"
            onClick={(event) => {
              row.original.userIsDisabled ?
                enableUser(event, row.original) :
                disableUser(event, row.original)
            }}
          >
            {row.original.userIsDisabled ? <PersonAddIcon color='primary' /> : <PersonAddDisabledIcon color='error' />}
          </IconButton>
        </Tooltip>
      </RowActionsContainer>

    ),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    manualPagination: true,
    manualSorting: true,
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setQuery,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    state: {
      columnFilters,
      globalFilter: query,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />
  );
}

