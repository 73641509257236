import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import DocumentName from "documents/values/document-name";
import DocumentTopic from "documents/values/document-topic";
import { DocumentType } from "documents/values/document-type";
import moment, { Moment } from "moment";

export enum WorkDocumentType {
  Conflicts = "Conflicts",
  ClientPolicy = "ClientPolicy",
  VendorPolicy = "VendorPolicy"
} 

export default class WorkDocument extends Document {
  public id: Guid;
  public replacesId?: Guid;
  public documentType: WorkDocumentType;

  constructor(    
    id: Guid,
    name: DocumentName | undefined,
    creatorId: Guid | null,
    fileSize: number,
    fileType: DocumentType,
    created: Moment | undefined,
    readAccess: Guid[] | undefined,
    writeAccess: Guid[] | undefined,
    topics: DocumentTopic[],
    templateIds: Guid[],
    documentType: WorkDocumentType,
    replacesId?: Guid
  ) {
    super(id, name, creatorId, fileSize, fileType, created, readAccess, writeAccess, topics, templateIds);
    this.id = id;
    this.replacesId = replacesId;
    this.documentType = documentType;
  }

  public get isEmpty(): boolean {
    return false;
  }
  
  public static fromDocument(document: Document, documentType: WorkDocumentType, replacesId?: Guid) {
    return new WorkDocument(
      document.id,
      document.name,
      document.creatorId,
      document.fileSize,
      document.fileType,
      document.created,
      document.readAccess,
      document.writeAccess,
      document.topics,
      document.templateIds,
      documentType,
      replacesId
    );
  }
  public override fromObject(object: any) : WorkDocument | null {
    if (!object) return null;

    const id = Guid.fromObject(object.id) ?? Guid.fromJSON(object.id);
    const creatorId = Guid.fromObject(object.creatorId) ?? null;
    const name = object.name ? new DocumentName(object.name) : undefined;
    const fileSize = parseInt(object.fileSize);
    const fileType = object.fileType as DocumentType;
    const created = object.created ? moment(object.created) : undefined;
    const readAccess = object.readAccess?.map((id: any) => Guid.fromObject(id));
    const writeAccess = object.writeAccess?.map((id: any) => Guid.fromObject(id));
    const topics = object.topics?.map((topic: any) => DocumentTopic.fromObject(topic));
    const templateIds = object.templateIds?.map((id: any) => Guid.fromObject(id));
    const replacesId = Guid.fromObject(object.replacesId);

    if (!id) throw new Error("Document Id is required");

    return new WorkDocument(
      id,
      name,
      creatorId,
      fileSize,
      fileType,
      created,
      readAccess,
      writeAccess,
      topics,
      templateIds,
      object.documentType,
      replacesId
    );
  }

  clone(): WorkDocument {
    return new WorkDocument(
      this.id,
      this.name,
      this.creatorId,
      this.fileSize,
      this.fileType,
      this.created,
      this.readAccess,
      this.writeAccess,
      this.topics,
      this.templateIds,
      this.documentType,
      this.replacesId
    );
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      documentType: this.documentType,
      replacesId: this.replacesId?.toString()
    };
  }

  isEqualTo(document: WorkDocument | null): boolean {
    return Boolean(document && this.id.isEqualTo(document.id));
  }

  public static get Prototype(): WorkDocument {
    return new WorkDocument(
      Guid.generate(),
      undefined,
      Guid.generate(),
      0,
      DocumentType.PDF,
      moment(),
      [],
      [],
      [],
      [],
      WorkDocumentType.ClientPolicy
    );
  }

  public toString(): string {
    return this.name?.toString() ?? "Untitled Document";
  }
}
