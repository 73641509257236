import { HubConnectionState } from '@microsoft/signalr';
import { HubName, useRealtime } from 'app/providers/realtime';
import { CanceledError } from 'axios';
import { useEffect } from "react";
import NetworkConnectionAPIResponse from 'users/entities/user-network-connection/api/response-contracts/network-connection-api-response';
import NetworkInvitationAPIResponse from 'users/entities/user-network-invitation/api/response-contracts/network-invitation-api-response';
import { useSession } from "users/session/session-context";
import UserInformationAPIService from 'users/values/user-information/api/user-information-api-service';

export function RealtimeNetworking({ children }: Readonly<{ children: React.ReactNode }>) {
    const session = useSession();
    const { connection, startConnection } = useRealtime(HubName.Networking);

    //todo: updated network connection and invitation should trigger a session clone instead of an api refresh call
    async function onUserNetworkConnectionChange(payload: NetworkConnectionAPIResponse) {
        if (!session.user?.id)
            return;

        try {
            const response = Object.assign(new NetworkConnectionAPIResponse(), payload);
            if (!response.deserializeToConnectionProfile(session.user.id)) return;

            const abortController: AbortController = new AbortController();
            const userService: UserInformationAPIService = new UserInformationAPIService(session);

            await session.refresh(userService, abortController);
        } catch (error: any) {
            if (error instanceof CanceledError) return;
            console.error('Realtime networking on connection change failed: ', error);
        }
    }

    //todo: updated network connection and invitation should trigger a session clone instead of an api refresh call
    async function onUserNetworkInvitationChange(payload: NetworkInvitationAPIResponse) {
        if (!session.user?.id)
            return;

        try {
            const response = Object.assign(new NetworkInvitationAPIResponse(), payload);
            if (!response.deserializeToInfo(session.user.id)) return;

            const abortController: AbortController = new AbortController();
            const userService: UserInformationAPIService = new UserInformationAPIService(session);

            await session.refresh(userService, abortController);
        } catch (error: any) {
            if (error instanceof CanceledError) return;
            console.error('Realtime networking on invitation change failed: ', error);
        }
    }

    useEffect(() => {
        if (!connection)
            return;

        if (connection.state === HubConnectionState.Connected) {
            setEventHandlers();
            return;
        }

        startConnection()
            .then(() => {
                setEventHandlers();
            })
            .catch(err => console.error('Realtime networking hub connection failed: ', err));
    }, [connection]);

    function setEventHandlers() {
        if (!connection) return;

        connection.on("network-connection-created", onUserNetworkConnectionChange);
        connection.on("network-connection-updated", onUserNetworkConnectionChange);
        connection.on("network-connection-deleted", onUserNetworkConnectionChange);
        connection.on("network-invitation-created", onUserNetworkInvitationChange);
        connection.on("network-invitation-updated", onUserNetworkInvitationChange);
        connection.on("network-invitation-deleted", onUserNetworkInvitationChange);
    }

    return <>{children}</>;
}