import Guid from "common/values/guid/guid";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import Company from "../company/company";

export default class Individual {
  id: Guid;
  userId: Guid;
  profile?: IndividualProfile;
  entityId?: Guid;
  company?: Company;
  isVisible: boolean = false;

  constructor(id: Guid, userId: Guid, profile?: IndividualProfile, company?: Company, isVisible?: boolean) {
    this.id = id;
    this.userId = userId;
    this.profile = profile;
    this.entityId = company?.entityId;
    this.company = company;
    this.isVisible = isVisible ?? false;
  }

  public get isEmpty(): boolean {
    return false;
  }

  getFullName(): string {
    if (!this.profile) return 'N/A';
    return this.profile.getFullName();
  }

  toString(): string {
    return this.getFullName();
  }

  getInitials(): string {
    if (!this.profile) return 'N/A';
    return `${this.profile.firstName[0]}${this.profile.lastName[0]}`;
  }

  clone(): Individual {
    return new Individual(this.id, this.userId, this.profile, this.company, this.isVisible);
  }

  isEqualTo(other: Individual | null): boolean {
    if (!other) return false;
    return this.id.isEqualTo(other.id) || this.userId.isEqualTo(other.userId)
  }

  public fromObject(object: any) : Individual | null {
    if (!object) return null;

    const individualId = Guid.fromObject(object.id);
    const userId = Guid.fromObject(object.userId);
    const profile = IndividualProfile.fromObject(object.profile);
    const company = Company.fromObject(object.company);

    if (!individualId) throw new Error("Individual Id is required");
    if (!userId) throw new Error("User Id is required");

    return new Individual(
      individualId,
      userId,
      profile, 
      company, 
      object.isVisible
    );
  }
  public toJSON(): any {
    return {
      id: this.id.toJSON(),
      userId: this.userId.toJSON(),
      profile: this.profile?.toJSON(),
      entityId: this.entityId?.toJSON(),
      company: this.company?.toJSON(),
      isVisible: this.isVisible
    }
  }

  public static get Prototype(): Individual {
    return new Individual(Guid.generate(), Guid.generate());
  }
}
