import Guid from "common/values/guid/guid";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import UserProfileAPIResponse from "users/entities/user/api/response-contracts/user-profile-api-response";

export default class NetworkInvitationAPIResponse {
  id?: string;
  status?: string;
  inviter?: UserProfileAPIResponse;
  invitee?: UserProfileAPIResponse;
  created?: string;
  message?: string;

  deserializeToInfo(userId: Guid): NetworkInvitationInfo {
    if(!this.id) 
      throw new Error("id is required");
    if(this.inviter === undefined) 
      throw new Error("inviter is required");
    if(!this.inviter) 
      throw new Error("individualId is required");
    if(!this.invitee) 
      throw new Error("user is required");
    if(!this.created) 
      throw new Error("created is required");

    const isInviter: boolean = this.inviter.id === userId.value; 
    const otherUser = isInviter ? this.invitee : this.inviter;
    const deserializedUser = Object.assign(new UserProfileAPIResponse(), otherUser).deserialize();

    return new NetworkInvitationInfo(
      new Guid(this.id),
      this.status ?? "",
      isInviter,
      deserializedUser.id,
      undefined,
      deserializedUser,
      new Date(this.created),
      this.message ?? ""
    );
  }
}
