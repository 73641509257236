import Guid from "common/values/guid/guid";
import reactElementToJSXString from "react-element-to-jsx-string";
import { ProposalField } from "work/entities/proposal/proposal";
import {
  RedlineChange
} from "work/entities/proposal/redlining/redline-change";
import { ProposalSpecChange } from "work/entities/proposal/utils/proposal-builder";

export default class AutoCommentGenerator {

  public static generateAutoCommentTextFromSpecChange(specChange: ProposalSpecChange): string {
    throw new Error("Method not implemented.");
  } 

  public static generateAutoCommentTextFromRedlineChange(redlineChange: RedlineChange): string {
    if (!redlineChange.action) {
        throw new Error("Redline change action is required to generate auto comment text.");
    }

    const redlineComment = AutoCommentGenerator.renderRedlineChange(redlineChange);

    let commentRedlineString = reactElementToJSXString(redlineComment, {
      tabStop: 0,
    });
    if(redlineChange.field?.isEqualTo(ProposalField.TeamRestriction)){
      commentRedlineString = "Team Restriction";
    } else if(redlineChange.field?.isEqualTo(ProposalField.WaiveConflictsCheck)){
      commentRedlineString = "Conflicts Check Waiver";
    }
    const cleanedCommentRedlineString = commentRedlineString
      .replaceAll("\n", " ")
      .replaceAll(/{'\s*'}/g, "");

    const autoCommentText = `
      <span className="action ${redlineChange.action?.actionDescription.toLowerCase()}">${
        redlineChange.action?.actionDescription
    }</span>:
      ${cleanedCommentRedlineString}
    `;

    return autoCommentText;
  } 

  private static renderRedlineChange(redlineChange: RedlineChange) {
    if (!redlineChange.textChanges || redlineChange.textChanges.length === 0){
      throw new Error("Redline change text changes are required to render redline change.");
    };

    let nodes = [];
    for (let changeIndex = 0; changeIndex < redlineChange.textChanges.length; changeIndex++) {
      if (
        redlineChange.textChanges?.[changeIndex - 1]?.isModified &&
        redlineChange.textChanges[changeIndex].isModified
      ) {
        continue;
      }

      const value = redlineChange.textChanges[changeIndex]?.diff.value;
      const nextValue = redlineChange.textChanges[changeIndex + 1]?.diff.value;

      if (
        redlineChange.textChanges[changeIndex].isModified &&
        redlineChange.textChanges[changeIndex + 1]?.isModified
      ) {
        const node = (
          <span key={Guid.generate().value} className="change-group">
            <span className={`removed ${redlineChange.isResolved ? "resolved" : ""}`}>
              {value}
            </span>
            <span className={`added ${redlineChange.isResolved ? "resolved" : ""}`}>
              {nextValue}
            </span>
          </span>
        );
        nodes.push(node);
        continue;
      }

      let className = "unchanged";
      if (redlineChange.textChanges[changeIndex].isAdded) className = "added";
      if (redlineChange.textChanges[changeIndex].isRemoved) className = "removed";
      if (redlineChange.isResolved) className += " resolved";
      const node = (
        <span key={Guid.generate().value} className={className}>
          {value}
        </span>
      );
      nodes.push(node);
    }

    return (
      <span key={Guid.generate().value} className="comment-redline">
        {nodes.map((node) => node)}
      </span>
    );
  }
}
