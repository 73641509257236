export default class PaginatedResponse<T> {
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  data: Array<T>;

  constructor(
    data: Array<T>,
    pageIndex: number, 
    pageSize: number, 
    totalElements: number,
    totalPages: number
  ) {
    this.data = data;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.totalElements = totalElements;
    this.totalPages = totalPages;
  }
}
