import { AccountType } from "common/values/account-type/account-type";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import Session from "users/session/session";
import UserInformationAPIService from "users/values/user-information/api/user-information-api-service";

export const SessionContext = createContext<Session>(undefined!);

export function SessionProvider({ children }: { readonly children: React.ReactNode }) {
  const [session, setSession] = useState<Session>();

  useEffect(() => {
    const initializeSession = async () => {
      const initialSession = Session.loadFromStorage(setSession);

      if (initialSession.isLoggedIn && !initialSession.context) {
        const userInformationService = new UserInformationAPIService(initialSession);
        const abortController = new AbortController();
        const success = await initialSession.refresh(userInformationService, abortController);
        if (!success) {
          window.location.href = '/login';
        }
      }
      let accountType: AccountType;
      if (initialSession.context?.viewingAsVendor === undefined) {
        accountType = initialSession.context?.isVendor ? AccountType.Vendor : AccountType.Client;
      } else {
        accountType = initialSession.context.viewingAsVendor ? AccountType.Vendor : AccountType.Client;
      }
      await initialSession.setAccountViewType(accountType);
      setSession(initialSession);
    };
    initializeSession();
  }, []);

  return (
    <SessionContext.Provider value={session!}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSession(): Session {
  let context = useContext(SessionContext);

  //#2188 bug fix for useSession being called before the context is initialized in the SessionProvider 
  //removing the session instanciation will result in a provider error.
  if (!context) {
    context = Session.loadFromStorage(() => { console.warn("Session not initialized yet") });
  }

  return context;
}

export function RequireSession({ children }: { readonly children: React.JSX.Element }) {
  const session = useSession();
  const location = useLocation();

  const [isRefreshing, setIsRefreshing] = useState(false);

  // This effect runs on router location changes.
  useEffect(() => {
    if (!session || !session.isLoggedIn || isRefreshing) return;
    setIsRefreshing(true);
    try {
      const userInformationService = new UserInformationAPIService(session);
      const abortController = new AbortController();
      session.refresh(userInformationService, abortController).then(success => {
        setIsRefreshing(false);
        if (!success) {
          window.location.href = '/login';
        }
      });
    } catch (error) {
      console.error("Unable to refresh session", error);
      setIsRefreshing(false);
    }
  }, [location]);

  if (!session.isLoggedIn) {
    // Redirect user to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to forward them
    // along to that page after they login.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}