import Guid from "common/values/guid/guid";
import moment from "moment";
import UserNetworkConnection from "users/entities/user-network-connection/user-network-connection";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import UserProfileAPIResponse from "users/entities/user/api/response-contracts/user-profile-api-response";

export default class NetworkConnectionAPIResponse {
    id?: string;
    createdDate?: string;
    userA?: UserProfileAPIResponse;
    userB?: UserProfileAPIResponse;

    deserialize(): UserNetworkConnection {
        if (!this.id)
            throw new Error(`Invalid network connection response: missing id.`);
        if (!this.createdDate)
            throw new Error(`Invalid network connection response: missing createdDate.`);
        if (!this.userA)
            throw new Error(`Invalid network connection response: missing userA.`);
        if (!this.userB)
            throw new Error(`Invalid network connection response: missing userB.`);

        return new UserNetworkConnection(
            new Guid(this.id),
            moment(this.createdDate),
            Object.assign(new UserProfileAPIResponse(), this.userA).deserialize(),
            Object.assign(new UserProfileAPIResponse(), this.userB).deserialize()
        );
    }

    deserializeToConnectionProfile(userId: Guid): UserNetworkConnectionProfile {
        if(!this.id || !this.createdDate || !this.userA || !this.userB) 
            throw Error("Invalid network connection response");

        this.userA = Object.assign(new UserProfileAPIResponse(), this.userA);
        this.userB = Object.assign(new UserProfileAPIResponse(), this.userB);
        const otherUser = this.userA.id === userId.value ? this.userB : this.userA;
        return new UserNetworkConnectionProfile(
            new Guid(this.id),
            otherUser.deserialize(),
            moment(this.createdDate)
        );
    }
}
