import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ForumIcon from "@mui/icons-material/Forum";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MessageIcon from "@mui/icons-material/Message";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EmptyInboxPlaceholder from 'app/assets/empty_inbox_placeholder.svg';
import { useDialog } from "app/providers/dialog";
import { NotificationTag } from "app/routes/communications/style";
import { DialogProps } from "common/components/dialog";
import PaginationParameters from "common/contracts/pagination-parameters";
import * as Constants from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import MessagingAPIService from "messaging/api/messaging-api-service";
import { ChatDialog } from "messaging/components";
import Forum from "messaging/entities/forum/forum";
import CreateForumDialog from "messaging/entities/forum/view/components/create-forum-dialog";
import moment from "moment";
import MessageNotification from "notifications/entities/message-notification/message-notification";
import NotificationParameters from "notifications/entities/notification/api/request-contracts/notification-parameters";
import Notification from "notifications/entities/notification/notification";
import NotificationsAPIService, { NotificationNotFoundError } from "notifications/services/notifications-api-service";
import Action from "notifications/values/action/action";
import { BoxType } from "notifications/values/box-type/box-type";
import TopicCategory from "notifications/values/topic-category/topic-category";
import React, { useEffect, useState } from "react";
import {
  Params,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSession } from "users/session/session-context";
import { CanceledError } from "axios";
import { HubName, useRealtime } from "app/providers/realtime";
import { HubConnectionState } from "@microsoft/signalr";
import NotificationAPIResponse from "notifications/entities/notification/api/response-contracts/notification-api-response";

const NoRowsContainer = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "center"
}));
const RowActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  width: "inherit"
}));
const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const ToolbarActionButtons = styled("div")(({ theme }) => ({
  textAlign: "right",
  whiteSpace: "nowrap",
}));
const CategoryItem = styled(ListItem)(({ theme }) => ({
  margin: 0,
  padding: 0,
}));
const AlertActions = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "nowrap",
  height: '100%',
  paddingRight: theme.spacing(2),
  whiteSpace: "nowrap"
}));
const AllCaughtUp = styled('img')(({ theme }) => ({
  maxHeight: '100px',
  padding: theme.spacing(2),
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const FilterButtons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& > .MuiChip-root": {
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.spacing(0.5),
    },
    marginLeft: theme.spacing(0.5),
  }
}));
const TableTitleContainer = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
}));
const NewForumButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  paddingRight: theme.spacing(1)
}));
const ComposeMessageButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  paddingRight: theme.spacing(1)
}));

type CommunicationTableEntry = MessageNotification & Forum;
type CommunicationsProps = {};

export default function Communications(_props: Readonly<CommunicationsProps>) {
  const [activeTab, setActiveTab] = React.useState(BoxType.Inbox);
  const [actionRequired, setActionRequired] = React.useState(false);
  const [unread, setUnread] = React.useState(false);

  const [data, setData] = useState<CommunicationTableEntry[]>([]);
  const [wasErrorLoading, setWasErrorLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>({});

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const { openDialog, closeAllDialogs } = useDialog();
  const { connection, startConnection } = useRealtime(HubName.Notification);
  const isSmlDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isMedDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  useEffect(() => {
    if (!connection)
      return;

    if (connection.state === HubConnectionState.Connected) {
      setEventHandlers();
      return;
    }

    startConnection()
      .then(() => {
        setEventHandlers();
      })
      .catch(err => console.error('Realtime notifications hub connection failed: ', err));
  }, [connection]);

  function setEventHandlers() {
    if (!connection)
      return;

    connection.on('notification-received', handleNotificationReceived);
    connection.on('notification-updated', handleNotificationReceived);
    connection.on('notification-deleted', handleNotificationDeleted);
  }

  // Handle URL changes
  useEffect(() => {
    if (!session.user?.companyEntityId) return;

    let abortController = new AbortController();

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as BoxType);
    } else {
      navigate(`/communications/${BoxType.Inbox}`);
    }

    if (routeParams.action === "compose") {
      openMessageDialog();
      return;
    }
    if (routeParams.tab === BoxType.Messages && routeParams.id) {
      openMessageDialog(new Guid(routeParams.id));
    }

    setUnread(searchParams.get("unread") === "true");
    setActionRequired(searchParams.get("actionRequired") === "true");

    fetchData(
      routeParams.tab as BoxType,
      unread,
      actionRequired,
      abortController
    );

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [routeParams, searchParams]);

  const fetchData = async (
    tab: BoxType,
    unread: boolean,
    actionRequired: boolean,
    abortController?: AbortController
  ) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    const boxType = tab ?? activeTab;

    const paginationParams = new PaginationParameters(
      pagination.pageIndex,
      pagination.pageSize
    );
    const notificationParams = new NotificationParameters(
      boxType,
      actionRequired,
      unread,
      false
    );

    try {
      let notifications: CommunicationTableEntry[] = [];
      const notificationService = new NotificationsAPIService(session);
      if (boxType === BoxType.Inbox || boxType === BoxType.Outbox) {
        const recievedNotifications =
          await notificationService.getNotificationsForUser(
            paginationParams,
            notificationParams,
            abortController
          );
        notifications.push(
          ...(recievedNotifications as CommunicationTableEntry[])
        );
      }
      if (boxType === BoxType.Messages) {
        const messageService = new MessagingAPIService(session);
        let chats = await messageService.getForums(undefined, undefined, undefined, abortController);
        notifications.push(...(chats as CommunicationTableEntry[]));
      }
      if (boxType === BoxType.Archived) {
        const archivedNotifications =
          await notificationService.getArchivedNotificationsForUser(
            paginationParams,
            notificationParams,
            abortController
          );
        notifications.push(
          ...(archivedNotifications as CommunicationTableEntry[])
        );
      }
      setData(notifications);
      setRowCount(notifications.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setWasErrorLoading(true);
      console.error(error);
      return;
    }
    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function handleNotificationReceived(rawApiNotification: Readonly<Partial<NotificationAPIResponse>>) {
    const apiReponse = new NotificationAPIResponse(rawApiNotification);
    const notification = apiReponse.deserialize();
    if (notification.recipientUserId?.isEqualTo(session.user?.id)) {
      fetchData(activeTab, unread, actionRequired);
    }
  }

  function handleNotificationDeleted(rawApiNotification: Readonly<Partial<NotificationAPIResponse>>) {
    //the backend does not send a complete notification object when a notification is deleted, so we can't deserialize it
    const apiReponse = new NotificationAPIResponse(rawApiNotification); 
    if (apiReponse.recipientUserId === session.user?.id?.toString()) {
      fetchData(activeTab, unread, actionRequired);
    }
  }

  async function navigateToTarget(communication: CommunicationTableEntry) {
    const notification = communication as Notification | MessageNotification;
    const topicCategory = notification.topic?.category;

    try {
      if (activeTab === BoxType.Inbox) {
        await new NotificationsAPIService(session).markNotificationAsSeen(
          notification
        );
        navigate(getRedirectUrl(notification));
      }

      if (activeTab === BoxType.Messages) {
        navigate(`/communications/messages/view/${notification.id}`);
      }

      if (
        topicCategory === TopicCategory.Messaging.Message ||
        topicCategory === TopicCategory.Messaging.ForumInvitation
      ) {
        navigate(
          `/communications/messages/view/${(notification as MessageNotification).messageInfo?.forum.id
          }`
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getRedirectUrl(notification: Notification) {
    const id = notification.topic?.id;
    const category = notification.topic?.category ?? "";
    const isProposalMessageNotification =
      (notification as MessageNotification).messageInfo?.forum.entityClass
        .value === "Work.Proposal";
    const action = notification.actionRequired;
    let actionType: "view" | "edit" | "revise" | "review" = "view";
    let pageTab = "active";
    let tab = "";
    let url = "";

    if (!isProposalMessageNotification) {
      url =
        Constants.notificationLinks[
        category as keyof typeof Constants.notificationLinks
        ];
    } else {
      const proposalId = (notification as MessageNotification).messageInfo
        ?.forum.entityId;
      return `/proposals/active/revise/${proposalId}/chat`;
    }

    if (!id) return url;

    switch (action) {
      case Action.DraftProposalReview:
        pageTab = "drafts";
        actionType = "review";
        break;
      case Action.DraftProposalReviewApproved:
        pageTab = "drafts";
        actionType = "edit";
        break;
      case Action.ProposalReview:
        actionType = "review";
        break;
      case Action.Review:
      case Action.ProposalRevisionReview:
      case Action.ProposalReviewApproved:
      case Action.HireOrCancel:
        actionType = "revise";
        break;
    }

    url = url.replace("{pageTab}", pageTab);
    url = url.replace("{action}", actionType);
    url = url.replace("{topicId}", id.value);
    return url.replace("{tab}", tab);
  }

  async function markMultipleSeen() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter(id => rowSelection[id] === true)
      .map(id => new Guid(id));

    try {
      setIsLoading(true);

      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).markNotificationAsSeen(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log("Unable to mark a deleted notification as read, refreshing to remove", error.id);
            continue;
          }
          throw error;
        }
      }

      setRowSelection({});
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function markMultipleUnseen() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter(id => rowSelection[id] === true)
      .map(id => new Guid(id));

    try {
      setIsLoading(true);

      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).markNotificationAsUnSeen(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log("Unable to mark a deleted notification as unread, refreshing to remove", error.id);
            continue;
          }
          throw error;
        }
      }

      setRowSelection({});
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function archiveMultiple() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter(id => rowSelection[id] === true)
      .map(id => new Guid(id));

    try {
      setIsLoading(true);

      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).archiveNotification(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log("Unable to archive a deleted notification, refreshing to remove ", error.id);
            continue;
          }
          throw error;
        }
      }

      setRowSelection({});
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function unArchiveMultiple() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter(id => rowSelection[id] === true)
      .map(id => new Guid(id));

    try {
      setIsLoading(true);

      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).unArchiveNotification(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log("Unable to un-archive a deleted notification, refreshing to remove ", error.id);
            continue;
          }
          throw error;
        }
      }

      setRowSelection({});
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function openMessageDialog(id?: Guid) {
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: id ? "Message" : "Compose Message",
        MuiProps: {
          fullWidth: true,
          maxWidth: "md",
        },
        contentSxProps: {
          display: "flex",
          overflowX: "hidden",
          paddingBottom: 0,
        },
        component: (
          <ChatDialog
            forumId={id}
            onLeaveConversation={() => {
              fetchData(
                activeTab,
                unread,
                actionRequired
              );
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function renderNameColumn(
    communication: CommunicationTableEntry
  ): React.ReactNode {
    const forum = communication as Forum;

    return (
      <CategoryItem dense>
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText
          primary={forum?.name}
          primaryTypographyProps={{ fontWeight: '500' }}
        />
      </CategoryItem>
    );
  }

  function renderCreatedDateColumn(
    communication: CommunicationTableEntry
  ): React.ReactNode {
    if (!communication.createdDate) return null;
    return (
      <time>
        {moment(communication.createdDate).format("MM/DD/YY hh:mm A")}
      </time>
    );
  }

  function renderCategoryColumn(communication: CommunicationTableEntry) {
    const notification = communication as Notification;
    const topicCategory: TopicCategory = notification?.topic?.category ?? "";

    return (
      <CategoryItem dense>
        <ListItemIcon>
          {(topicCategory === TopicCategory.Messaging.Message ||
            topicCategory === TopicCategory.Messaging.ForumInvitation) && (
              <MessageIcon />
            )}
          {topicCategory === TopicCategory.Work.Proposal &&
            !notification.actionRequired && <PersonAddIcon />}
          {topicCategory === TopicCategory.Users.NetworkConnection && (
            <AccountTreeIcon />
          )}
          {topicCategory === TopicCategory.Users.NetworkInvitation && (
            <AccountTreeIcon />
          )}
          {topicCategory === TopicCategory.Work.Proposal &&
            notification.actionRequired &&
            notification.actionRequired !== Action.Review && <AssignmentIcon />}
          {topicCategory === TopicCategory.Work.Proposal &&
            notification.actionRequired === Action.Review && <PageviewIcon />}
          {topicCategory === TopicCategory.Marketplace.Team && <GroupIcon />}
          {topicCategory === TopicCategory.LegalEntities.EntityMember && (
            <PersonIcon />
          )}
          {topicCategory === TopicCategory.Marketplace.TeamInvitation && (
            <GroupAddIcon />
          )}
          {topicCategory === TopicCategory.LegalEntities.LegalEntity && (
            <SupervisorAccountIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <strong
              style={{ fontWeight: !notification.isSeen ? "bold" : "normal" }}
            >
              {topicCategory === TopicCategory.Messaging.Message
                ? "New Message"
                : Constants.notificationTypes[
                topicCategory as keyof typeof Constants.notificationTypes
                ] ?? "Notification"}
            </strong>
          }
          secondary={
            activeTab === BoxType.Inbox &&
            (notification.isSeen
              ? `Read at ${moment(notification.seenAt).format(
                "MM/DD/YY hh:mm A"
              )}`
              : "Unread notification")
          }
        />
      </CategoryItem>
    );
  }

  function renderDescriptionColumn(communication: CommunicationTableEntry) {
    const notification = communication as Notification;
    const messageNotification = communication as MessageNotification;

    const tag = notification.recipientUserId?.isEqualTo(session.user?.id) ? (
      <NotificationTag size="small" label="Received" />
    ) : (
      <NotificationTag size="small" label="Sent" />
    );

    const messageInfo =
      notification.topic?.category === TopicCategory.Messaging.Message ? (
        <>
          <strong style={{ fontWeight: 500 }}>
            {messageNotification.messageInfo?.forum.name.value}
          </strong>
          <br />
          <span>
            {messageNotification.messageInfo?.subject &&
              `Subject: ${messageNotification.messageInfo.subject.value}`}
          </span>
        </>
      ) : (
        notification.message?.value
      );

    return (
      <>
        {activeTab === BoxType.Archived && tag}
        {messageInfo}
      </>
    );
  }

  const renderInboxRowActions = (communication: CommunicationTableEntry) => {
    return (
      <RowActionsContainer>
        {!communication.isSeen ? (
          <Tooltip title="Mark as Read">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  markAsRead(communication);
                }}
              >
                <MarkEmailReadIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Mark as Unread">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  markAsUnread(communication);
                }}
              >
                <MarkEmailReadIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Archive">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                archive(communication);
              }}
            >
              <ArchiveIcon />
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>)
  }

  const renderArchiveRowActions = (communication: CommunicationTableEntry) => {
    return (
      <RowActionsContainer>
        <Tooltip title="Restore from Archive">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                unArchive(communication);
              }}
            >
              <UnarchiveIcon />
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>)
  }

  async function markAsRead(communication: CommunicationTableEntry) {
    if (!communication || communication?.seenAt) return;
    try {
      await new NotificationsAPIService(session).markNotificationAsSeen(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log("Unable to mark a deleted notification as read ", error.id);
      } else {
        console.error(error);
      }
    } finally {
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    }
  }

  async function markAsUnread(communication: CommunicationTableEntry) {
    if (!communication || !communication?.seenAt) return;
    try {
      await new NotificationsAPIService(session).markNotificationAsUnSeen(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log("Unable to mark a deleted notification as unread, refreshing to remove ", error.id);
      } else {
        console.error(error);
      }
    } finally {
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    }
  }

  async function archive(communication: CommunicationTableEntry) {
    if (!communication || communication instanceof Forum) return;
    try {
      await new NotificationsAPIService(session).archiveNotification(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log("Unable to archive a deleted notification, refreshing to remove ", error.id);
      } else {
        console.error(error);
      }
    } finally {
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    }
  }

  async function unArchive(communication: CommunicationTableEntry) {
    if (!communication || communication instanceof Forum) return;
    try {
      await new NotificationsAPIService(session).unArchiveNotification(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log("Unable to un-archive a deleted notification, refreshing to remove ", error.id);
      } else {
        console.error(error);
      }
    } finally {
      fetchData(
        activeTab,
        unread,
        actionRequired
      );
    }
  }

  const columnHelper = createMRTColumnHelper<CommunicationTableEntry>();
  const columns = [];

  if (activeTab !== BoxType.Messages) {
    columns.push(...[
      columnHelper.accessor(renderCategoryColumn, {
        header: "Type",
        id: "type",
      }),
      columnHelper.accessor(renderDescriptionColumn, {
        header: "Description",
        id: "description",
      })
    ]);
  };
  if (activeTab === BoxType.Messages) {
    columns.push(
      columnHelper.accessor(renderNameColumn, {
        header: "Conversation Name",
        id: "name",
      })
    );
  }
  columns.push(
    columnHelper.accessor(renderCreatedDateColumn, {
      header: "Created",
      id: "createdDate",
    })
  );

  const forumDialogProps: DialogProps = {
    component: <CreateForumDialog onForumCreated={(forum: Forum) => {
      closeAllDialogs();
      let updatedForums = [...data];
      updatedForums.push(forum as CommunicationTableEntry);
      setData(updatedForums);
      navigate(`/communications/messages/view/${forum.id}`);
    }} />,
    title: "Create Conversation",
    MuiProps: {
      maxWidth: "md",
    },
  };

  function getRowClickTheme(row: CommunicationTableEntry): SxProps<Theme> | undefined {
    if (activeTab === BoxType.Archived) {
      return {
        cursor: "",
        backgroundColor: "rgba(0, 0, 0, 0.04)"
      };
    }
    return {
      cursor: "pointer",
      backgroundColor: !row.isSeen ? "inherit" : "rgba(0, 0, 0, 0.04)"
    };
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: activeTab !== BoxType.Messages,
    enableRowActions: activeTab !== BoxType.Messages,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigateToTarget(row.original),
      sx: getRowClickTheme(row.original),
    }),
    muiTableProps: ({ table }) => ({
      sx: {
        height: table.getRowCount() === 0 ? '100%' : "initial"
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMedDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <ToolbarActionButtons>
              <Button
                color="primary"
                startIcon={<AddCommentIcon />}
                onClick={() => navigate("/communications/messages/compose")}
              >
                Compose Message
              </Button>
              <Button
                color="primary"
                startIcon={<ForumIcon />}
                onClick={() => openDialog(forumDialogProps)}
              >
                New Conversation
              </Button>
            </ToolbarActionButtons>
          </ToolbarButtonsContainer>
        )}
        {isMedDisplaySize && !isSmlDisplaySize && (
          <>
            <ComposeMessageButton
              color="primary"
              onClick={() => navigate("/communications/messages/compose")}
            >
              <AddCommentIcon fontSize="large" />
            </ComposeMessageButton>
            <NewForumButton
              color="primary"
              onClick={() => openDialog(forumDialogProps)}
            >
              <ForumIcon fontSize="large" />
            </NewForumButton>
          </>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitleContainer>
            <TableTitle variant="h4">
              Communications
            </TableTitle>
            {isSmlDisplaySize && (
              <div>
                <ComposeMessageButton
                  color="primary"
                  onClick={() => navigate("/communications/messages/compose")}
                >
                  <AddCommentIcon fontSize="large" />
                </ComposeMessageButton>
                <NewForumButton
                  color="primary"
                  onClick={() => openDialog(forumDialogProps)}
                >
                  <ForumIcon fontSize="large" />
                </NewForumButton>
              </div>
            )}
          </TableTitleContainer>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) =>
                navigate(`/communications/${newValue}`)
              }
            >
              <Tab value={BoxType.Inbox} label="Inbox" />
              <Tab value={BoxType.Outbox} label="Sent" />
              <Tab value={BoxType.Messages} label="Conversations" />
              <Tab value={BoxType.Archived} label="Archived" />
            </Tabs>
            {activeTab === BoxType.Inbox && (
              <FilterButtons>
                <Chip
                  icon={<FilterListIcon />}
                  label="Unread"
                  color="primary"
                  variant={unread ? 'filled' : 'outlined'}
                  onClick={() => {
                    setUnread(prevState => !prevState);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!unread) {
                        params.set('unread', 'true');
                      } else {
                        params.delete('unread');
                      }
                      return params;
                    });
                  }}
                />
                <Chip
                  icon={<FilterListIcon />}
                  label="Action Required"
                  color="primary"
                  variant={actionRequired ? 'filled' : 'outlined'}
                  onClick={() => {
                    setActionRequired(prevState => !prevState);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!actionRequired) {
                        params.set('actionRequired', 'true');
                      } else {
                        params.delete('actionRequired');
                      }
                      return params;
                    });
                  }}
                />
              </FilterButtons>
            )}
          </LowerTopToolbarContainer>
          {isMedDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    renderRowActions: ({ row }) => {
      if (activeTab === BoxType.Archived) {
        return renderArchiveRowActions(row.original);
      }
      if (activeTab === BoxType.Inbox) {
        return renderInboxRowActions(row.original)
      }
    },
    renderEmptyRowsFallback: () => (
      <NoRowsContainer>
        <AllCaughtUp alt="All Caught Up" src={EmptyInboxPlaceholder} />
      </NoRowsContainer>
    ),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      columnPinning: { right: ["mrt-row-actions"] },
      columnOrder: ["type", "description", "name", "createdDate", "actions"],
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading ? {
      color: "error"
    } : {
      action: (
        <AlertActions>
          {activeTab === BoxType.Archived && (
            <Button
              color="primary"
              startIcon={<UnarchiveIcon />}
              onClick={unArchiveMultiple}
            >
              Un-Archive
            </Button>
          )}
          {(activeTab === BoxType.Inbox) && (
            <>
              <Button
                color="primary"
                startIcon={<MarkEmailReadIcon />}
                onClick={markMultipleSeen}
              >
                Mark Read
              </Button>
              <Button
                color="primary"
                startIcon={<MarkEmailUnreadIcon />}
                onClick={markMultipleUnseen}
              >
                Mark Unread
              </Button>
            </>
          )}
          {activeTab !== BoxType.Archived && (
            <Button
              color="primary"
              startIcon={<ArchiveIcon />}
              onClick={archiveMultiple}
            >
              Archive
            </Button>
          )}
        </AlertActions>
      ),
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
      rowSelection
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <MaterialReactTable table={table} />
  );
}
