import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import EntityAgreement from "legal-entities/entities/entity-agreement/entity-agreement";
import Company from "marketplace/entities/company/company";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import { NetworkStatus } from "users/entities/user-network-connection/view/components/network-button";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import AuthEmailAPIService, {
  AuthResponse,
  ExpiredTokenError,
} from "users/entities/user/api/auth-email-api-service";
import { AuthContext } from "users/entities/user/api/response-contracts/auth-info-api-response";
import User from "users/entities/user/user";
import UserAuthToken from "users/values/user-auth-token/user-auth-token";
import UserBookmarkInfo from "users/values/user-bookmark-info/user-bookmark-info";
import UserCredentials from "users/values/user-credentials/user-credentials";
import UserEntityInfo from "users/values/user-entity-info/user-entity-info";
import UserInformationAPIService from "users/values/user-information/api/user-information-api-service";
import UserInformation from "users/values/user-information/user-information";
import UserProfile from "users/values/user-profile/user-profile";

class Session {
  private _user: User | null = null;
  private _company: Company | null = null;
  private _context: AuthContext | null = null;
  private _legalEntities: UserEntityInfo[] = [];
  private _openEntityAgreements: EntityAgreement[] = [];
  private _networkConnections: UserNetworkConnectionProfile[] = [];
  private _networkInvitations: NetworkInvitationInfo[] = [];
  private _bookmarkedTeams: UserBookmarkInfo[] = [];
  private _bookmarkedIndividuals: UserBookmarkInfo[] = [];
  private _bookmarkedCompanies: UserBookmarkInfo[] = [];
  private _authToken?: UserAuthToken;
  private _updateSession:
    | Dispatch<SetStateAction<Session | undefined>>
    | undefined;

  public toJSON(): object {
    const json = {
      _user: this._user ? this._user.toJSON() : this._user,
      _company: this._company ? this._company.toJSON() : this._company,
      _context: this._context ? this._context.toJSON() : this._context,
      _legalEntities: this._legalEntities?.map((entity) => entity.toJSON()) ?? [],
      _openEntityAgreements: this._openEntityAgreements?.map((agreement) =>
        agreement.toJSON()
      ) ?? [],
      _networkConnections: this._networkConnections?.map((connection) =>
        connection.toJSON()
      ) ?? [],
      _networkInvitations: this._networkInvitations?.map((invitation) =>
        invitation.toJSON()
      ) ?? [],
      _bookmarkedTeams: this._bookmarkedTeams?.map((bookmark) =>
        bookmark.toJSON()
      ) ?? [],
      _bookmarkedIndividuals: this._bookmarkedIndividuals?.map((bookmark) =>
        bookmark.toJSON()
      ) ?? [],
      _bookmarkedCompanies: this._bookmarkedCompanies?.map((bookmark) =>
        bookmark.toJSON()
      ) ?? [],
      _authToken: this._authToken ? this._authToken.toJSON() : this._authToken,
    }
    return json;
  }
  public static loadFromStorage(
    updateSession: Dispatch<SetStateAction<Session | undefined>>
  ): Session {
    const sessionData = localStorage.getItem("Session");
    const parsedSessionData = sessionData ? JSON.parse(sessionData) : null;

    if (!parsedSessionData?._authToken?._value) {
      const newSession = new Session();
      newSession._updateSession = updateSession;
      return newSession;
    }

    let session: Session;
    try {
      session = Session.fromObject(parsedSessionData, updateSession);
    } catch (SessionLoadError) {
      console.error("Error loading session: ", SessionLoadError);
      localStorage.removeItem("Session");
      session = new Session();
      session._updateSession = updateSession;
    }
    return session;
  }

  public login = async (
    userCrededentials: UserCredentials,
    remember: boolean
  ) => {
    const authResponse: AuthResponse = await AuthEmailAPIService.login(
      userCrededentials,
      remember
    );

    this.initializeFromAuthResponse(authResponse);
  };

  public refresh = async (
    userService: UserInformationAPIService,
    abortController: AbortController
  ): Promise<boolean> => {
    try {
      const userInfoResponse: UserInformation =
        await userService.getUserInformation(this, abortController);
      this.refreshFromUserInfo(userInfoResponse);
      return true;
    } catch (error: any) {
      if (error instanceof ExpiredTokenError) {
        this.logout();
        return false;
      } else {
        throw error;
      }
    }
  };

  public logout = async () => {
    localStorage.removeItem("Session");
    this._user = null;
    this._company = null;
    this._context = null;
    this._legalEntities = [];
    this._openEntityAgreements = [];
    this._networkConnections = [];
    this._networkInvitations = [];
    this._bookmarkedTeams = [];
    this._bookmarkedIndividuals = [];
    this._bookmarkedCompanies = [];
    this._authToken = undefined;
    window.location.assign("/login");
  };

  public refreshFromUserInfo(userInfo: UserInformation) {
    this._user = new User(userInfo.fullName, userInfo.emailAddress, userInfo.id, userInfo.isSuperUser);
    this._user.individualId = userInfo.individualId;
    this._user.companyEntityId = userInfo.legalEntities[0]
      ? userInfo.legalEntities[0].entityId
      : undefined;
    this._context = new AuthContext(
      userInfo.isVendor,
      userInfo.isClient,
      userInfo.viewingAsVendor,
      userInfo.isVendorRepresentative,
      userInfo.isClientRepresentative,
      moment()
    );

    this._legalEntities = userInfo.legalEntities;
    this._openEntityAgreements = userInfo.openEntityAgreements.map(
      (agreement) => EntityAgreement.fromUserOpenEntityAgreement(agreement)
    );
    this._networkConnections = userInfo.networkConnections;
    this._networkInvitations = userInfo.networkInvitations;
    this._bookmarkedTeams = userInfo.bookmarkedTeams;
    this._bookmarkedIndividuals = userInfo.bookmarkedIndividuals;
    this._bookmarkedCompanies = userInfo.bookmarkedCompanies;
    localStorage.setItem("Session", JSON.stringify(this));
  }

  public initializeFromAuthResponse(authResponse: AuthResponse) {
    this._user = authResponse.user;
    this._context = authResponse.context;
    this._authToken = authResponse.authToken;
    this._legalEntities = authResponse.legalEntities;
    this._openEntityAgreements = authResponse.openEntityAgreements.map(
      (agreement) => EntityAgreement.fromUserOpenEntityAgreement(agreement)
    );
    this._networkConnections = authResponse.networkConnections;
    this._networkInvitations = authResponse.networkInvitations;
    this._bookmarkedTeams = authResponse.bookmarkedTeams;
    this._bookmarkedIndividuals = authResponse.bookmarkedIndividuals;
    this._bookmarkedCompanies = authResponse.bookmarkedCompanies;
    localStorage.setItem("Session", JSON.stringify(this.toJSON()));
    AttorneyHubAPIService.setAuthHeader(this._authToken?.value);
    this._updateSession?.(this);
  }

  public isNetworkedWith = (userId: Guid): boolean => {
    for (const connection of this._networkConnections) {
      if (connection.user.id.isEqualTo(userId)) return true;
    }
    return false;
  };

  public async setAccountViewType(accountType: AccountType) {
    if (!this._updateSession) {
      console.warn("Cannot update session.");
    }
    if (!this.context) return;

    const newContext = new AuthContext(
      this.context.isVendor,
      this.context.isClient,
      accountType === AccountType.Vendor,
      this.context.isVendorRepresentative,
      this.context.isClientRepresentative,
      moment()
    );

    await this.setContext(newContext);
    const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
    this._updateSession?.(updatedSession);
    localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
  }

  public get canSwitchContext(): boolean {
    if (!this._context) return false;
    return this._context.isVendor && this._context.isClient;
  }

  public async setContext(newContext: AuthContext) {
    if (!this._context) return;
    this._context = newContext;
    //check for invalid account view combination
    if (this._context.viewingAsVendor && this._context.isVendor === false) {
      this._context.viewingAsVendor = false;
    } else if (
      this._context.viewingAsVendor === false &&
      this._context.isClient === false
    ) {
      this._context.viewingAsVendor = true;
    }
    localStorage.setItem("Session", JSON.stringify(this));
    if (this.isLoggedIn) {
      try {
        await AuthEmailAPIService.updateUserVendorView(
          newContext.viewingAsVendor ?? false,
          this.authToken
        );
      } catch (error: any) {
        if (error instanceof ExpiredTokenError) this.logout();
        else throw error;
      }
    }
  }

  public get user(): User | null {
    return this._user;
  }

  public get context(): AuthContext | null {
    return this._context;
  }

  public get authToken(): UserAuthToken | undefined {
    return this._authToken;
  }

  public get company(): Company | null {
    return this._company;
  }

  public get entities(): UserEntityInfo[] {
    return this._legalEntities;
  }

  public get currentEntity(): UserEntityInfo {
    return this._legalEntities[0]
  }

  public get openEntityAgreements(): EntityAgreement[] {
    return this._openEntityAgreements;
  }

  public get networkConnections(): UserNetworkConnectionProfile[] {
    return this._networkConnections;
  }

  public get networkInvitations(): NetworkInvitationInfo[] {
    return this._networkInvitations;
  }

  public get isLoggedIn(): boolean {
    return !!this._user && !!this._authToken && !this._authToken.isExpired;
  }

  public get isLoggedInAsSuperUser(): boolean {
    return this._authToken?.hasSuperUserScope === true;
  }

  public get isLoggedInAsAdmin(): boolean {
    if (!this._user) return false;
    return this._legalEntities.some((entity) => entity.isAdmin);
  }

  public get accountType(): AccountType {
    if (!this._user || !this._context) return AccountType.None;
    return this._context?.viewingAsVendor
      ? AccountType.Vendor
      : AccountType.Client;
  }

  public get bookmarkedCompanies(): UserBookmarkInfo[] {
    return this._bookmarkedCompanies;
  }

  public get bookmarkedTeams(): UserBookmarkInfo[] {
    return this._bookmarkedTeams;
  }

  public get bookmarkedIndividuals(): UserBookmarkInfo[] {
    return this._bookmarkedIndividuals;
  }

  /**
   * Is true if the user is a representative of either a vendor or client
   */
  public get isRepresentative(): boolean {
    if (!this._context) return false;
    return (
      this._context.isVendorRepresentative ||
      this._context.isClientRepresentative
    );
  }

  /**
   * Is true if the user is a representative of the context they are viewing as
   */
  public get isContextRepresentative(): boolean {
    if (!this._context) return false;
    return (
      (this._context.isVendorRepresentative && this._context.viewingAsVendor) ||
      (this._context.isClientRepresentative && !this._context.viewingAsVendor)
    );
  }

  /**
   * Is true if the user has at least one legal entity
   */
  public get hasEntity(): boolean {
    return this._legalEntities.length > 0;
  }

  public updateAuthToken = (value?: UserAuthToken) => {
    this._authToken = value;
  };

  public static fromObject(
    object: any,
    updateSession: Dispatch<SetStateAction<Session | undefined>> | undefined
  ): Session {
    const session = new Session();
    session._updateSession = updateSession;

    try {
      const authToken = new UserAuthToken(
        object._authToken._value,
        moment(object._authToken._expiry)
      );
      session._authToken = authToken;
      AttorneyHubAPIService.setAuthHeader(session._authToken.value);
    } catch (InvalidTokenError) {
      console.error("Invalid token: ", object._authToken.value);
      AttorneyHubAPIService.setAuthHeader(null);
      throw new SessionLoadError("Invalid token");
    }

    session._user = object._user ? User.fromObject(object._user) : null;
    session._context = object._context
      ? AuthContext.fromObject(object._context)
      : null;
    session._legalEntities = object._legalEntities
      ? object._legalEntities.map((entity: any) => UserEntityInfo.fromObject(entity))
      : null;
    session._openEntityAgreements = object._openEntityAgreements
      ? object._openEntityAgreements.map((agreement: any) =>
          EntityAgreement.fromObject(agreement)
        )
      : null;
    session._networkConnections = object._networkConnections
      ? object._networkConnections.map((connection: any) =>
          UserNetworkConnectionProfile.fromObject(connection)
        )
      : null;
    session._networkInvitations = object._networkInvitations
      ? object._networkInvitations.map((invitation: any) =>
          NetworkInvitationInfo.fromObject(invitation)
        )
      : null;
    session._bookmarkedTeams = object._bookmarkedTeams
      ? object._bookmarkedTeams.map((bookmark: any) =>
          UserBookmarkInfo.fromObject(bookmark)
        )
      : null;
    session._bookmarkedIndividuals = object._bookmarkedIndividuals
      ? object._bookmarkedIndividuals.map((bookmark: any) =>
          UserBookmarkInfo.fromObject(bookmark)
        )
      : null;
    session._bookmarkedCompanies = object._bookmarkedCompanies
      ? object._bookmarkedCompanies.map((bookmark: any) =>
          UserBookmarkInfo.fromObject(bookmark)
        )
      : null;

    return session;
  }

  public getNetworkConnectionByUserId = (
    userId: Guid | string
  ): UserNetworkConnectionProfile | undefined => {
    if (!userId) return undefined;
    const userIdGuid: Guid = userId instanceof Guid ? userId : new Guid(userId);
    for (const connection of this._networkConnections) {
      if (connection.user.id.value === userIdGuid.value) return connection;
    }
    return undefined;
  };

  public addNetworkConnection = (
    newConnection: UserNetworkConnectionProfile
  ) => {
    const index = this._networkConnections.findIndex((existingConnection) =>
      existingConnection.id.isEqualTo(newConnection.id)
    );
    if (index >= 0) {
      return;
    }
    let newConnections = [...this._networkConnections];
    newConnections.push(newConnection);
    this._networkConnections = newConnections;
    this._networkInvitations = this._networkInvitations.filter(
      (invitation) => invitation.user.id.value !== newConnection.user.id.value
    );
    const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
    this._updateSession?.(updatedSession);
    localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
  };

  public updateNetworkConnection = (
    connection: UserNetworkConnectionProfile
  ) => {
    const index = this._networkConnections.findIndex((connection) =>
      connection.id.isEqualTo(connection.id)
    );
    if (index >= 0) {
      let newConnections = [...this._networkConnections];
      newConnections[index] = connection;
      this._networkConnections = newConnections;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public updateNetworkConnections = (connections: NetworkedUserInfo[]) => {
    this._networkConnections = connections.map((connection) => {
      return new UserNetworkConnectionProfile(
        connection.connectionId,
        new UserProfile(
          connection.userId,
          connection.name,
          connection.email,
          connection.isClientRep,
          connection.isVendorRep
        ),
        connection.createdDate ?? moment()
      );
    });
    const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
    this._updateSession?.(updatedSession);
    localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
  };

  public removeNetworkConnectionById = (connectionId: Guid) => {
    const index = this._networkConnections.findIndex((connection) =>
      connection.id.isEqualTo(connectionId)
    );
    if (index >= 0) {
      let newConnections = [...this._networkConnections];
      newConnections.splice(index, 1);
      this._networkConnections = newConnections;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public removeNetworkConnectionByUserId = (userId: Guid) => {
    const index = this._networkConnections.findIndex((element) =>
      element.user.id.isEqualTo(userId)
    );
    if (index >= 0) {
      let newConnections = [...this._networkConnections];
      newConnections.splice(index, 1);
      this._networkConnections = newConnections;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public addNetworkInvitation = (invitation: NetworkInvitationInfo) => {
    const index = this._networkInvitations.findIndex(
      (element) => element.user.id.value === invitation.user.id.value
    );
    if (index >= 0) {
      return;
    }
    let newInvitations = [...this._networkInvitations];
    newInvitations.push(invitation);
    this._networkInvitations = newInvitations;
    const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
    this._updateSession?.(updatedSession);
    localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
  };

  public updateNetworkInvitation = (invitation: NetworkInvitationInfo) => {
    const index = this._networkInvitations.findIndex(
      (element) => element.user.id.value === invitation.user.id.value
    );
    if (index >= 0) {
      let newInvitations = [...this._networkInvitations];
      newInvitations[index] = invitation;
      this._networkInvitations = newInvitations;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public removeNetworkInvitationById = (invitationId: Guid) => {
    const index = this._networkInvitations.findIndex(
      (invitation) => invitation.id.isEqualTo(invitationId)
    );
    if (index >= 0) {
      let newInvitations = [...this._networkInvitations];
      newInvitations.splice(index, 1);
      this._networkInvitations = newInvitations;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public removeNetworkInvitationByUser = (userId: Guid | string) => {
    if (!userId) return;
    const userIdGuid: Guid = userId instanceof Guid ? userId : new Guid(userId);
    const index = this._networkInvitations.findIndex(
      (element) => element.user.id.value === userIdGuid.value
    );
    if (index >= 0) {
      let newInvitations = [...this._networkInvitations];
      newInvitations.splice(index, 1);
      this._networkInvitations = newInvitations;
      const updatedSession = Session.fromObject(this.toJSON(), this._updateSession);
      this._updateSession?.(updatedSession);
      localStorage.setItem("Session", JSON.stringify(updatedSession.toJSON()));
    }
  };

  public isNetworkInvited = (userId: Guid | string): boolean => {
    if (!userId) return false;
    const userIdGuid: Guid = userId instanceof Guid ? userId : new Guid(userId);
    for (const invitation of this._networkInvitations) {
      if (invitation.user.id.value === userIdGuid.value) return true;
    }
    return false;
  };

  public getNetworkInvitation = (
    userId: Guid | string
  ): NetworkInvitationInfo | undefined => {
    if (!userId) return undefined;
    const userIdGuid: Guid = userId instanceof Guid ? userId : new Guid(userId);
    for (const element of this._networkInvitations) {
      if (element.user.id.value === userIdGuid.value) return element;
    }
    return undefined;
  };

  public getNetworkStatus(userId: Guid) : NetworkStatus {
    if (userId.isEqualTo(this.user?.id)) {
      return NetworkStatus.Self;
    }
    if (this.isNetworkedWith(userId)) {
      return NetworkStatus.InNetwork;
    } 
    const invitation = this.getNetworkInvitation(userId);
    if (invitation) {
      if(invitation.status === NetworkStatus.Rejected)
        return NetworkStatus.Rejected;
      return invitation.inviter ? NetworkStatus.Sent : NetworkStatus.Received;
    }
    return NetworkStatus.None;

  }
}

export default Session;

export class SessionLoadError extends Error {}
