import Forum from "messaging/entities/forum/forum";
import { ProposalField } from "work/entities/proposal/proposal";
import { TopicContext } from "work/entities/proposal/proposal-forum-topic-context";


export function getForumForField(
  field: ProposalField | undefined,
  commentForums: Forum[] = [],
): Forum | undefined {

  if  (!field || !commentForums) return;

  return commentForums.find((forum) => {
    if (!forum.topic?.context) return false;

    const context = TopicContext.fromObject(JSON.parse(forum.topic.context));
    return context?.field.isEqualTo(field);
  });
}
