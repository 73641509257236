import PaginationParameters from "common/contracts/pagination-parameters";
import UserAPIResponse from "users/entities/super-user/api/response-contracts/user-api-response";
import User from "users/entities/user/user";

export interface PagedUsersData {
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  entries: UserAPIResponse[];
}

export default class PagedUsersAPIResponse extends PaginationParameters{
  totalPages: number;
  totalCount: number;
  entries: UserAPIResponse[];

  hasPrevious() { return this.pageIndex > 0; }
  hasNext() { return this.pageIndex < this.totalPages; }

  constructor(data: PagedUsersData) {
    super(data.pageIndex, data.pageSize);
    this.totalPages = data.totalPages;
    this.totalCount = data.totalCount;
    this.entries = [];
    data.entries.forEach((entry: UserAPIResponse) => {
        try {
          const userData = Object.assign(new UserAPIResponse(), entry);
          this.entries.push(userData);
        }
        catch (error) {
          console.error(`Error deserializing user data: ${error}`, entry);
        }
      });
  }
  
  deserializeUsers() : User[] {
    return this.entries.map((userApiResponse) => userApiResponse.deserialize());
  }
}