import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditNoteIcon from "@mui/icons-material/EditNote";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  BaseTextFieldProps,
  IconButton,
  InputBaseComponentProps,
  InputProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Percent from "common/values/percent/percent";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { forwardRef, useEffect } from "react";
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from "react-number-format";
import { ProposalField } from "work/entities/proposal/proposal";
import DiffText from "work/entities/proposal/redlining/_diff/view/diff-text";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";

const RedlineTextBox = styled(TextField)(({ theme }) => ({
  minWidth: "20rem",
  "& .MuiTypography-root": {
    height: "unset",
  },
  width: "min-content",
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  paddingRight: theme.spacing(2),
  width: "100%",
}));
const InputContainer = styled("span")(({ theme }) => ({
  "&.MuiInputBase-input": {
    display: "flex",
    flexDirection: "column",
    height: "unset",
  },
}));
const ChangeContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontSize: "0.875rem",
  width: "100%",
}));
const DiffHeader = styled(Typography)(({ theme }) => ({
  color: lightBlue[500],
  display: "inline-block",
  fontSize: "0.75rem",
  lineHeight: 1,
  minWidth: theme.spacing(13.5),
}));

interface InputSubComponentProps extends InputBaseComponentProps {
  originalPercentFieldRedline: FieldRedline<Percent>;
  hideCommentsButton: boolean;
  hideAcceptRejectButtons: boolean;
  hideUndoButton: boolean;
  commentForums: Forum[];
  focused: boolean;
  onTraverseIn?: () => void;
  onPercentFieldRedlineChange?: (
    newRedline: FieldRedline<Percent>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onActionButtonsChange?: (actionButtons: JSX.Element) => void;
  onCommentsClicked?: () => void;
}

const InputSubComponent = forwardRef<HTMLInputElement, InputSubComponentProps>((props, forwardedRef) => {
    const {
      className,
      originalPercentFieldRedline,
      hideCommentsButton,
      hideAcceptRejectButtons,
      hideUndoButton,
      commentForums,
      focused,
      required,
      onTraverseIn,
      onPercentFieldRedlineChange,
      onCommentsClicked,
      onActionButtonsChange,
      onFocus,
      onBlur,
    } = props;

    const [currentPercentNumeral, setCurrentPercentNumeral] =
      React.useState<number>(0);
    const [currentRedline, setCurrentRedline] = React.useState<
      FieldRedline<Percent>
    >(originalPercentFieldRedline.clone());
    const [showPercentInput, setShowPercentInput] =
      React.useState<boolean>(false);

    useEffect(() => {
      setCurrentRedline(originalPercentFieldRedline.clone());
      setCurrentPercentNumeral(
        originalPercentFieldRedline.currentEntry?.numeral ??
          originalPercentFieldRedline.revisedEntry?.numeral ??
          0
      );
    }, []);
    useEffect(() => {
      onActionButtonsChange?.(renderActionButtons());
    }, [showPercentInput, originalPercentFieldRedline, currentRedline]);

    function renderAcceptRejectButtons() {
      return (
        <>
          <Tooltip title="Accept All Changes">
            <span>
              <ActionButton
                onClick={() => {
                  try {
                    const newRedline = originalPercentFieldRedline.accept();
                    setCurrentRedline(newRedline);
                    onPercentFieldRedlineChange?.(newRedline);
                  } catch (error) {
                    console.error("Error accepting all changes", error);
                    enqueueSnackbar("Error accepting all changes", {
                      variant: "error",
                    });
                  }
                }}
              >
                <CheckIcon color="success" />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip title="Reject All Changes">
            <span>
              <ActionButton
                onClick={() => {
                  try {
                    const newRedline = originalPercentFieldRedline.reject();
                    setCurrentRedline(newRedline);
                    onPercentFieldRedlineChange?.(newRedline);
                  } catch (error) {
                    console.error("Error rejecting all changes", error);
                    enqueueSnackbar("Error rejecting all changes", {
                      variant: "error",
                    });
                  }
                }}
              >
                <CloseIcon color="error" />
              </ActionButton>
            </span>
          </Tooltip>
        </>
      );
    }

    function renderUndoButton() {
      return (
        <Tooltip title="Undo Changes">
          <span>
            <ActionButton
              onClick={() => {
                try {
                  const newRedline = originalPercentFieldRedline.undo();
                  onPercentFieldRedlineChange?.(newRedline, {
                    name: newRedline.field,
                    id: newRedline.fieldId,
                  });
                } catch (error) {
                  console.error("Error undoing changes", error);
                  enqueueSnackbar("Error undoing changes", {
                    variant: "error",
                  });
                }
              }}
            >
              <UndoIcon />
            </ActionButton>
          </span>
        </Tooltip>
      );
    }

    function renderEditButton() {
      return (
        <Tooltip title="Edit">
          <span>
            <ActionButton
              onClick={(event) => {
                setCurrentRedline(originalPercentFieldRedline.clone());
                setCurrentPercentNumeral(
                  originalPercentFieldRedline.currentEntry?.value ??
                    originalPercentFieldRedline.revisedEntry?.value ??
                    0
                );
                setShowPercentInput(true);
                onTraverseIn?.();
              }}
            >
              <EditNoteIcon />
            </ActionButton>
          </span>
        </Tooltip>
      );
    }

    function renderTextInputButtons() {
      let isChanged =
        currentPercentNumeral !==
        (originalPercentFieldRedline?.currentEntry?.value ??
          originalPercentFieldRedline?.revisedEntry?.value ??
          0);
      if (currentRedline.isResolved) {
        isChanged =
          currentPercentNumeral !==
          originalPercentFieldRedline.currentEntry?.value;
      }
      return (
        <>
          {isChanged && (
            <Tooltip title="Save Change">
              <span>
                <IconButton
                  disabled={required && currentPercentNumeral === 0}
                  onClick={() => {
                    try {
                      onPercentFieldRedlineChange?.(currentRedline, currentRedline.field);
                      setShowPercentInput(false);
                    } catch (error) {
                      console.error("Error saving changes", error);
                      enqueueSnackbar("Error saving changes", {
                        variant: "error",
                      });
                    }
                  }}
                >
                  <CheckIcon color="primary" fontSize="medium" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Cancel">
            <span>
              <IconButton
                onClick={() => {
                  setCurrentRedline(originalPercentFieldRedline.clone());
                  setCurrentPercentNumeral(
                    originalPercentFieldRedline.currentEntry?.value ??
                      originalPercentFieldRedline.revisedEntry?.value ??
                      0
                  );
                  onActionButtonsChange?.(renderActionButtons());
                  setShowPercentInput(false);
                  onTraverseIn?.();
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </span>
          </Tooltip>
        </>
      );
    }

    function renderCommentButton() {
      if (hideCommentsButton) return null;
      return (
        <Tooltip title="Comments">
          <span>
            <IconButton
              onClick={() => {
                onCommentsClicked?.();
                onTraverseIn?.();
              }}
            >
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !getForumForField(
                    originalPercentFieldRedline.field,
                    commentForums
                  )
                }
              >
                <CommentIcon fontSize="medium" />
              </Badge>
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    function renderActionButtons() {
      return (
        <>
          {!showPercentInput && (
            <>
              {!hideAcceptRejectButtons &&
                !originalPercentFieldRedline.isResolved &&
                renderAcceptRejectButtons()}
              {originalPercentFieldRedline.canBeUndone &&
                !hideUndoButton &&
                renderUndoButton()}
              {renderEditButton()}
            </>
          )}
          {showPercentInput && renderTextInputButtons()}
          {renderCommentButton()}
        </>
      );
    }

    function clampValue(): number {
      if (
        !currentPercentNumeral ||
        (currentPercentNumeral <= 1 && currentPercentNumeral >= 0)
      )
        return currentPercentNumeral;
      let newNumber = currentPercentNumeral;
      // Clamp to between 0 and 100%
      if (newNumber < 0) newNumber = 0;
      if (newNumber > 1) newNumber = 1;
      setCurrentPercentNumeral(newNumber);
      return newNumber;
    }

    return (
      <TextContainer>
        <InputContainer className={className}>
          {!showPercentInput &&
            originalPercentFieldRedline.changes.length > 0 && (
              <DiffText
                changes={originalPercentFieldRedline.changes}
                allResolved={originalPercentFieldRedline.isResolved}
                disablePopover
                onClick={() => {
                  setShowPercentInput(true);
                  onTraverseIn?.();
                }}
              />
            )}
          {showPercentInput && (
            <>
              <ChangeContainer>
                <DiffHeader variant="overline">previously:</DiffHeader>
                {currentRedline.originalEntry?.toString() ?? "0%"}
              </ChangeContainer>
              <ChangeContainer>
                <DiffHeader variant="overline">your changes:</DiffHeader>
                <DiffText
                  disablePopover={true}
                  changes={currentRedline.changes}
                  allResolved={currentRedline.isResolved}
                />
              </ChangeContainer>
            </>
          )}
          {!showPercentInput &&
            originalPercentFieldRedline.changes.length < 1 && (
              <Typography
                onClick={() => {
                  setShowPercentInput(true);
                  onTraverseIn?.();
                }}
              >
                0.00%
              </Typography>
            )}
          <NumericFormat
            inputRef={forwardedRef}
            focused={focused}
            placeholder="Enter new percentage"
            variant="standard"
            style={{
              height: showPercentInput ? "unset" : 0,
              width: showPercentInput ? "100%" : 0,
              pointerEvents: showPercentInput ? "auto" : "none",
              position: showPercentInput ? "relative" : "absolute",
            }}
            customInput={TextField}
            value={
              currentPercentNumeral != null
                ? Number(currentPercentNumeral * 100).toFixed(2)
                : 0
            }
            suffix="%"
            type="text"
            thousandSeparator={false}
            allowNegative={false}
            decimalScale={2}
            isAllowed={(values) => {
              const { floatValue } = values;
              return (
                floatValue == null || (floatValue >= 0 && floatValue <= 100)
              );
            }}
            onBlurCapture={() => {
              onPercentFieldRedlineChange?.(
                currentRedline.edit(new Percent(clampValue() ?? 0)),
                currentRedline.field
              );
              setShowPercentInput?.(false);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              try {
                if (event.key === "Enter" || event.key === "Tab") {
                  onPercentFieldRedlineChange?.(
                    currentRedline.edit(new Percent(clampValue() ?? 0)),
                    currentRedline.field
                  );
                  setShowPercentInput?.(false);
                }
                if (event.key === "Escape") {
                  clampValue();
                  setShowPercentInput?.(false);
                }
              } catch (error) {
                console.error("Error saving changes", error);
                enqueueSnackbar("Error saving changes", { variant: "error" });
              }
            }}
            onValueChange={(
              values: NumberFormatValues,
              sourceInfo: SourceInfo
            ) => {
              try {
                if (sourceInfo.source === "prop") return;
                let updatedDiscount = Number.parseFloat(
                  (
                    Math.floor((values.floatValue ?? 0) * 1000) * 0.00001
                  ).toPrecision(4)
                );
                setCurrentPercentNumeral(updatedDiscount);

                let newRedline = currentRedline.edit(
                  new Percent(updatedDiscount)
                );
                setCurrentRedline(newRedline);
                onActionButtonsChange?.(renderActionButtons());
              } catch (error) {
                console.error("Error changing value", error);
                enqueueSnackbar("Error changing value", { variant: "error" });
              }
            }}
            onClick={() => {
              setShowPercentInput(true);
              onTraverseIn?.();
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </InputContainer>
      </TextContainer>
    );
  }
);

export interface PercentRedlineInputProps extends BaseTextFieldProps {
  focused: boolean;
  originalPercentFieldRedline: FieldRedline<Percent>;
  showPercentInput?: boolean;
  readOnly?: boolean;
  hideUndoButton?: boolean;
  hideAcceptRejectButtons?: boolean;
  hideCommentsButton?: boolean;
  commentForums: Forum[];
  onTraverseIn?: () => void;
  onPercentFieldRedlineChange?: (
    newRedline: FieldRedline<Percent>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onCommentsClicked?: () => void;
}

const PercentRedlineInput = (props: Readonly<PercentRedlineInputProps>) => {
  const {
    focused,
    originalPercentFieldRedline,
    hideUndoButton,
    hideAcceptRejectButtons,
    readOnly,
    hideCommentsButton,
    variant,
    margin,
    commentForums,
    onPercentFieldRedlineChange,
    onCommentsClicked,
    onTraverseIn,
    onFocus,
    onBlur,
    ...otherProps
  } = props;

  const [actionButtons, setActionButtons] = React.useState<JSX.Element>();

  function getInputProps(variant: string | undefined): Partial<InputProps> {
    const inputProps: Partial<InputProps> = {
      inputComponent: InputSubComponent,
      inputProps: {
        readOnly,
        hideUndoButton,
        hideAcceptRejectButtons,
        hideCommentsButton,
        originalPercentFieldRedline,
        commentForums,
        focused,
        onTraverseIn,
        onPercentFieldRedlineChange,
        onCommentsClicked,
        onActionButtonsChange: (actionButtons: JSX.Element) => {
          setActionButtons(actionButtons);
        },
        onFocus: onFocus,
        onBlur: onBlur,
      } as InputSubComponentProps,
      endAdornment: actionButtons,
    };
    if (variant && variant !== "outlined") {
      inputProps.disableUnderline = true;
    }

    return inputProps;
  }

  return (
    <RedlineTextBox
      {...otherProps}
      focused={focused}
      label={originalPercentFieldRedline.label}
      variant={variant ?? "outlined"}
      margin={margin ?? "normal"}
      slotProps={{
        input: getInputProps(variant),
        inputLabel: { shrink: true },
      }}
      onFocus={onTraverseIn}
    />
  );
};

export default PercentRedlineInput;
