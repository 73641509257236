import PaginationParameters from "common/contracts/pagination-parameters";

export interface ColumnSort {
  desc: boolean;
  id: string;
}

export default class SearchUsersAPIRequest extends PaginationParameters {
  query: string;
  sort?: string;

  constructor(query: string, pageIndex: number, pageSize: number, sort?: ColumnSort) {
    super(pageIndex, pageSize);
    this.query = query;
    if (sort) {
      this.sort = `${sort?.id},${sort?.desc ? 'desc' : 'asc'}`;
    } else {
      this.sort = "lastName,asc";
    }
  }

  toUrl(route: string, apiBaseUrl: string): string {
    const url = new URL(route, apiBaseUrl);
    if (this.query) {
      url.searchParams.append("query", this.query);
    }
    if (this.pageIndex !== undefined) {
      url.searchParams.append("pageIndex", this.pageIndex.toString());
    }
    if (this.pageSize) {
      url.searchParams.append("pageSize", this.pageSize.toString());
    }
    if (this.sort) {
      url.searchParams.append("sort", this.sort);
    }
    return url.toString();
  }
}